import { Formik, useField } from "formik";
import { useState } from "react";
import { Icon } from "@iconify/react";

export const TextInput = ({ label, required, ...props }) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-4 w-full">
      <label
        htmlFor={props.id || props.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      {props.type === "password" ? (
        <div className="relative">
          <input
            {...field}
            {...props}
            type={showPassword ? "text" : "password"}
            className={`mt-1 block form-control py-2 h-[45px] w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              props.className || ""
            } ${meta.touched && meta.error ? "border-red-500" : ""}`}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-5 right-0 pr-3 flex items-center text-sm leading-5"
          >
            {showPassword ? (
              <Icon icon="heroicons-outline:eye-slash" />
            ) : (
              <Icon icon="heroicons-outline:eye" />
            )}
          </button>
        </div>
      ) : (
        <input
          {...field}
          {...props}
          className={`mt-1 block form-control py-2 h-[45px] w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
            props.className || ""
          } ${meta.touched && meta.error ? "border-red-500" : ""}`}
        />
      )}
      {meta.touched && meta.error ? (
        <div className="text-sm text-red-500 mt-2">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const SelectInput = ({ label, options, required, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-4 w-full">
      <label
        htmlFor={props.id || props.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <select
        {...field}
        {...props}
        className={`mt-1 block w-full form-control px-2 py-2 h-[45px] rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
          props.className || ""
        } ${meta.touched && meta.error ? "border-red-500" : ""}`}
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? (
        <div className="text-sm text-red-500 mt-2">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const ImageUploadInput = ({
  name,
  label,
  setFieldValue,
  initialValue,
  previewPosition = "below", // 'below' or 'right'
}) => {
  const [imagePreview, setImagePreview] = useState(initialValue || "");

  const handleImageChange = (e) => {
    const file = e.currentTarget.files[0];
    setFieldValue(name, file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  return (
    <div
      className={`flex ${
        previewPosition === "right" ? "flex-row" : "flex-col"
      }`}
    >
      <div>
        {label && (
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <input
          name={name}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-1 block w-full py-2"
        />
      </div>
      {imagePreview && (
        <div className={`${previewPosition === "right" ? "ml-4" : "mt-2"}`}>
          <img
            src={imagePreview}
            alt="Preview"
            className="w-32 h-32 object-cover"
          />
        </div>
      )}
    </div>
  );
};

