import Loading, { LoadingIcon } from "@/components/Loading";
import { ImageUploadInput, TextInput } from "@/components/forms/FormElements";
import Radio from "@/components/ui/Radio";
import { protectedApi } from "@/store/api/api";
import { Icon } from "@iconify/react";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import BackButton from "@/pages/components/backButton";

const CategoryWithNomineesForm = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.event);
  const [category, setCategory] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deletedNominees, setDeletedNominees] = useState([]);

  const { id: categoryId, eventId } = useParams();

  const showVotesTypes = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    imageUrl: yup.string(),
    showVotestoPublic: yup.boolean().required(""),
    nominees: yup.array().of(
      yup.object({
        text: yup.string().required("Nominee name is required"),
        code: yup.string().required("Unique code is required"),
        imageUrl: yup.string(),
      })
    ),
  });

  const navigateToEvents = () => {
    navigate("/events")
  }

  const deleteCategory = async () => {
    try {
      const res = await protectedApi.delete(`/api/categories/${categoryId}`);
      toast.success(res.data.message);
      navigateToEvents();
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  useEffect(() => {
    if (categoryId) {
      setIsLoading(true);
      protectedApi
        .get(`/api/categories/${categoryId}`)
        .then((res) => {
          console.log("Res", res.data.data);
          const category = res.data.data;
          setCategory({...category, title: category.title || category.question});
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [categoryId]);

  const handleImageUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `test-images/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    return await getDownloadURL(snapshot.ref);
  };

  const handleSubmitCategory = async (values) => {
    setIsSubmitting(true);
    const editCategoryData = {
      showVotestoPublic: values.showVotestoPublic,
      title: values.title,
      nomineesData: values.nominees,
      eventId: category.eventId,
      imageUrl: values.imageUrl || category.imageUrl,
      isActive: false || category.isActive,
      deletedNominees,
    };

    try {
      if (values.imageUrl && values.imageUrl instanceof File) {
        editCategoryData.imageUrl = await handleImageUpload(values.imageUrl);
      } else {
        editCategoryData.imageUrl = values.imageUrl;
      }

      const nominees = await Promise.all(
        values.nominees.map(async (nominee) => {
          if (nominee.imageUrl && nominee.imageUrl instanceof File) {
            console.log("file nom");
            nominee.imageUrl = await handleImageUpload(nominee.imageUrl);
          }
          return nominee;
        })
      );

      editCategoryData.nomineesData = nominees;

      const responseData = categoryId
        ? await protectedApi.put(
            `/api/categories/${categoryId}`,
            editCategoryData
          )
        : await protectedApi.post(`/api/categories/new`, {
            ...editCategoryData,
            eventId,
          });

      navigateToEvents()
      toast.success(responseData.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(resetMessage());
    }
  }, [message]);

  if (isLoading) return <Loading />;

  return (
    <div className="inline-block align-bottom bg-white dark:bg-slate-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full py-9">
      <div className="bg-white dark:bg-slate-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="flex justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-slate-300 mb-5">
                {categoryId ? "Edit Category" : "Add New Category"}
              </h3>
              <div className="dark:bg-slate flex justify-between gap-x-10 px-4 sm:px-6 sm:flex">
                <BackButton />
                <button
                  type="button"
                  className="btn btn-secondary text-center"
                  onClick={() => {
                    if (confirm("Are you sure you want to cancel?")) {
                      navigate("/events");
                    }
                  }}
                >
                  Cancel
                </button>

                {categoryId && (
                  <button
                    type="button"
                    className="btn btn-danger text-center"
                    onClick={async () => {
                      if (
                        confirm(
                          "Are you sure you want to delete this category?"
                        )
                      ) {
                        await deleteCategory();
                      }
                    }}
                  >
                    Delete Category
                  </button>
                )}
              </div>
            </div>
            <Formik
              initialValues={{
                title: category.title || category.question ||  "",
                showVotestoPublic: category.showVotestoPublic || false,
                imageUrl: category.imageUrl || "",
                nominees: category.nomineesData || [],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmitCategory}
              enableReinitialize
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <TextInput
                    name="title"
                    label="Title"
                    placeholder="Enter title"
                    className="mb-5 text-sm"
                  />
                  <div className="mb-3">
                    <label>Display votes to the public?</label>
                    <div className="flex space-x-4">
                      {showVotesTypes.map((item, index) => (
                        <Radio
                          key={index}
                          label={item.label}
                          name="showVotestoPublic"
                          value={item.value}
                          checked={values.showVotestoPublic === item.value}
                          onChange={() =>
                            setFieldValue("showVotestoPublic", item.value)
                          }
                          className="h-4 w-4"
                        />
                      ))}
                    </div>
                  </div>
                  <ImageUploadInput
                    name="imageUrl"
                    label="Image"
                    setFieldValue={setFieldValue}
                    initialValue={values.imageUrl}
                  />
                  <div className="my-6">
                    <FieldArray
                      name="nominees"
                      render={(arrayHelpers) => (
                        <div>
                          {values.nominees.map((nominee, index) => (
                            <div
                              key={nominee.id || index}
                              className="flex mb-4 py-4 border-b border-black-200 space-x-5"
                            >
                              <div className="w-8 mt-1">
                                <p className="text-xs font-bold">
                                  # {index + 1}
                                </p>
                              </div>
                              <div className="w-1/3">
                                <TextInput
                                  name={`nominees[${index}].text`}
                                  label="Nominee Name"
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="w-36">
                                <TextInput
                                  name={`nominees[${index}].code`}
                                  label="Unique Code"
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="w-auto">
                                <ImageUploadInput
                                  previewPosition="right"
                                  name={`nominees[${index}].imageUrl`}
                                  label="Nominee Image"
                                  setFieldValue={setFieldValue}
                                  initialValue={nominee.imageUrl}
                                />
                              </div>
                              <div className="flex items-center">
                                <button
                                  onClick={() => {
                                    if (values.nominees[index].id) {
                                      setDeletedNominees([
                                        ...deletedNominees,
                                        values.nominees[index].id,
                                      ]);
                                    }
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <Icon
                                    icon="heroicons-outline:trash"
                                    className="text-red-500 text-5xl"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="text-blue-500 flex items-center space-x-6"
                            onClick={() =>
                              arrayHelpers.push({
                                text: "",
                                code: "",
                                imageUrl: "",
                              })
                            }
                          >
                            <Icon
                              icon="heroicons-outline:plus"
                              className="text-blue-500 text-lg"
                            />
                            Add Nominee
                          </button>
                        </div>
                      )}
                    />
                  </div>
                  <div className="w-1/2 mt-10">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full py-3 text-center font-bold px-4 rounded bg-blue-600 text-white hover:bg-blue-700"
                    >
                      <div className="flex justify-center items-center">
                        {isSubmitting && <LoadingIcon />}
                        Submit
                      </div>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryWithNomineesForm;
