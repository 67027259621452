import Icon from "@/components/ui/Icon";
import useMobileMenu from "@/hooks/useMobileMenu";
import { toggleActiveChat } from "@/pages/app/chat/store";
import { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

const Navmenu = ({ menus }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleSubmenu = (i) => {
    if (activeSubmenu === i) {
      setActiveSubmenu(null);
    } else {
      setActiveSubmenu(i);
    }
  };

  const location = useLocation();
  const locationName = location.pathname.replace("/", "");
  const [mobileMenu, setMobileMenu] = useMobileMenu();
  const [activeMultiMenu, setMultiMenu] = useState(null);
  const dispatch = useDispatch();

  const toggleMultiMenu = (j) => {
    if (activeMultiMenu === j) {
      setMultiMenu(null);
    } else {
      setMultiMenu(j);
    }
  };

  const isLocationMatch = (targetLocation) => {
    return (
      locationName === targetLocation ||
      locationName.startsWith(`${targetLocation}/`)
    );
  };

  useEffect(() => {
    let submenuIndex = null;
    let multiMenuIndex = null;
    menus.forEach((item, i) => {
      if (isLocationMatch(item.link)) {
        submenuIndex = i;
      }

      if (item.child) {
        item.child.forEach((childItem, j) => {
          if (isLocationMatch(childItem.childlink)) {
            submenuIndex = i;
          }

          if (childItem.multi_menu) {
            childItem.multi_menu.forEach((nestedItem) => {
              if (isLocationMatch(nestedItem.multiLink)) {
                submenuIndex = i;
                multiMenuIndex = j;
              }
            });
          }
        });
      }
    });
    document.title = `KampaignLive  | ${locationName}`;

    setActiveSubmenu(submenuIndex);
    setMultiMenu(multiMenuIndex);
    dispatch(toggleActiveChat(false));
    if (mobileMenu) {
      setMobileMenu(false);
    }
  }, [location]);

  return (
    <>
      <ul>
        {menus.map((item, i) => {
          return (
            <li
              key={i}
              className={`single-sidebar-menu mb-5
              ${item.child ? "item-has-children" : ""}
              ${activeSubmenu === i ? "open" : ""}
              ${
                locationName === item.link ||
                (locationName === "" && item.link === "/")
                  ? "menu-item-active"
                  : ""
              }`}
            >
              {/* single menu with no children */}
              {!item.child && !item.isHeadr && (
                <NavLink className="menu-link" to={item.link}>
                  <span className="menu-icon flex-grow-0">
                    <Icon icon={item.icon} />
                  </span>
                  <div className="text-box flex-grow text-[14px]">
                    {item.title}
                  </div>
                  {item.badge && (
                    <span className="menu-badge">{item.badge}</span>
                  )}
                </NavLink>
              )}

              {/* only for menu label */}
              {item.isHeadr && !item.child && (
                <div className="menulabel">{item.title}</div>
              )}

              {/* sub menu parent */}
              {item.child && (
                <>
                  <div
                    className={`menu-link ${
                      activeSubmenu === i
                        ? "parent_active not-collapsed"
                        : "collapsed"
                    }`}
                    onClick={() => toggleSubmenu(i)}
                  >
                    <div className="flex-1 flex items-start">
                      <span className="menu-icon">
                        <Icon icon={item.icon} />
                      </span>
                      <div className="text-box text-[13.5px]">{item.title}</div>
                    </div>
                    <div className="flex-0">
                      <div
                        className={`menu-arrow transform transition-all duration-300 ${
                          activeSubmenu === i ? "rotate-90" : ""
                        }`}
                      >
                        <Icon icon="heroicons-outline:chevron-right" />
                      </div>
                    </div>
                  </div>

                  {/* Dropdown menu */}
                  <Collapse isOpened={activeSubmenu === i}>
                    <ul className="submenu">
                      {item.child.map((childItem, childIndex) => (
                        <li
                          key={childIndex}
                          className={`py-2 mt-3 rounded-lg ${
                            childItem.childlink === activeLink
                              ? "bg-gray-600"
                              : ""
                          }`}
                        >
                          <NavLink
                            to={childItem.childlink}
                            className="text-gray-300 text-[14px] ml-5 hover:dark:hover:text-blue-400"
                            onClick={() => setActiveLink(childItem.childlink)}
                          >
                            {childItem.childtitle}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Navmenu;
