import Button from "@/components/ui/Button";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ text = "Go back" }) => {
  const navigate = useNavigate();

  return (
    <Button
      icon="heroicons-outline:arrow-left"
      text={text}
      className="btn-dark dark:bg-slate-800 h-min text-sm font-normal ml-2 bg-green-500 text-white hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out"
      iconClass="text-lg"
      onClick={() => navigate(-1)}
    />
  );
};

export default BackButton;
