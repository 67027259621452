import { createAsyncThunk } from "@reduxjs/toolkit";
import { protectedApi } from "../store/api/api";

export const fetchEvents = createAsyncThunk("event/fetchEvents", async () => {
  const response = await protectedApi.get(`/api/events/organiser/my-events`);
  return response.data.data;
});

export const fetchEventCategory = createAsyncThunk(
  "event/fetchEventCategory",
  async (eventId) => {
    const response = await protectedApi.get(
      `/api/events/${eventId}/categories`
    );
    return response.data;
  }
);

export const editEvent = createAsyncThunk(
  "event/editEvent",
  async ({ eventId, event }) => {
    const response = await protectedApi.put(`/api/events/${eventId}`, event);
    return response.data;
  }
);

export const fetchEventCategoryNominees = createAsyncThunk(
  "event/fetchEventCategoryNominees",
  async (categoryId) => {
    const response = await protectedApi.get(
      `/api/categories/${categoryId}/nominees`
    );
    return response?.data?.data;
  }
);

export const createCategory = createAsyncThunk(
  "event/createNewCategory",
  async (category) => {
    const response = await protectedApi.post(`/api/categories/new`, category);
    return response.data;
  }
);

export const createNewEvent = createAsyncThunk(
  "event/createNewEvent",
  async (event) => {
    const response = await protectedApi.post(`/api/events/new`, event);
    console.log("response", response.data);
    return response.data;
  }
);

export const saveNewEvent = async (event) => {
  const response = await protectedApi.post(`/api/events/new`, event);
  console.log("response", response.data);
  return response.data;
};

export const createNominee = createAsyncThunk(
  "event/createNewNominee",
  async (nominee) => {
    const response = await protectedApi.post(`/api/nominees/new`, nominee);
    return response.data;
  }
);

export const deleteNominee = createAsyncThunk(
  "event/deleteNominee",
  async ({ nomineeId, categoryId }) => {
    const response = await protectedApi.delete(
      `/api/nominees?nomineeId=${nomineeId}&categoryId=${categoryId}`
    );
    return response.data;
  }
);

export const evictNominee = createAsyncThunk(
  "event/evictNominee",
  async (evictedNominee) => {
    const response = await protectedApi.put(
      `/api/nominees/evict`,
      evictedNominee
    );
    return response.data;
  }
);

export const restoreNominee = createAsyncThunk(
  "event/restoreNominee",
  async (restoredNominee) => {
    const response = await protectedApi.put(
      `/api/nominees/restore`,
      restoredNominee
    );
    return response.data;
  }
);

export const editNominee = createAsyncThunk(
  "event/editNominee",
  async (editedNominee) => {
    const response = await protectedApi.post(
      `/api/nominees/edit`,
      editedNominee
    );
    return response.data;
  }
);

export const editCategory = createAsyncThunk(
  "event/editCategory",
  async ({ eventId, editedCategory }) => {
    const response = await protectedApi.put(
      `/api/categories/${eventId}`,
      editedCategory
    );
    return response.data;
  }
);
