import Card from "@/components/ui/Card";
import React from "react";

const NotFound = ({ model }) => {
  return (
    <Card>
      <div className="text-center mt-16">
        <div className="mb-4">
          <svg
            className="w-20 h-20 text-gray-400 mx-auto"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Folder Icon */}
            <path d="M3 5H1v16c0 1.1.9 2 2 2h18v-2H3V5z"></path>
            <path d="M21 3H11l-2-2H3c-1.1 0-2 .9-2 2v4h20V5c0-1.1-.9-2-2-2z"></path>
            {/* Lightbulb Icon */}
            <path d="M13 10.5v3h-2v-3c0-.8.7-1.5 1.5-1.5S13 9.7 13 10.5zM12 18c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2z"></path>
            <path d="M8.56 14.23l1.43 1.43c-.67.18-1.3.48-1.81.9-.5.41-.9.93-1.19 1.5l-1.43-1.43c.51-.98 1.21-1.8 2.11-2.4.43-.29.91-.53 1.4-.73l-.6-.17zm6.88 0c.49.2.97.44 1.4.73.9.6 1.6 1.42 2.11 2.4l-1.43 1.43c-.29-.57-.69-1.09-1.19-1.5-.51-.42-1.14-.72-1.81-.9l1.43-1.43-.51-.23z"></path>
          </svg>
        </div>
        <h1 className="text-xl mb-4">No {model} found</h1>
      </div>
    </Card>
  );
};

export default NotFound;
