import axios from "axios";
import { signOut } from "../../pages/auth/common/firebaseConfig";
const baseURL = import.meta.env.VITE_API_URL;
import { toast } from "react-toastify";

const api = axios.create({
  baseURL,
});

const protectedApi = axios.create({
  baseURL,
});

console.log("baseURL:", baseURL);

protectedApi.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

protectedApi.interceptors.response.use(
  (response) => response,
  (error) => {
    let modifiedError = { ...error };
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Redirect to login page
          toast.error("invalid login credentials");
          window.location.href = "/auth/login";
          break;
        case 403:
          // Redirect to login page with an error message
          window.location.href = "/auth/login?error=accessdenied";
          signOut();
          localStorage.removeItem("token");
          break;
        // Handle other status codes as needed
        case 400: {
          modifiedError = {
            ...modifiedError,
            data: {
              message: error.response.data.message,
            },
          };
          break;
        }
        case 404: {
          modifiedError = {
            ...modifiedError,
            data: {
              message: error.response.data.message,
            },
          };
          break;
        }
        case 500: {
          modifiedError = {
            ...modifiedError,
            data: {
              message: error.response.data.message,
            },
          };
        }
      }
    }
    return Promise.reject(modifiedError);
  }
);

export { api, protectedApi };
