import React, { useState, useEffect } from "react";
import Modal from "@/components/ui/Modal";
import { useSelector, useDispatch } from "react-redux";
import { toggleEditModal } from "./store";
import Flatpickr from "react-flatpickr";
import { useForm, Controller, set } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormGroup from "@/components/ui/FormGroup";
import { ImageUploadInput } from "./ImageUpload";
import { editEvent, fetchEvents } from "@/utils/eventService";
import { toast } from "react-toastify";
import { resetMessage } from "./store";

const EditEvent = () => {
  const { editModal, editItem, message } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [imageUrl, setImageUrl] = useState("");
  const [resultsVisibility, setResultsVisibility] = useState(true);
  const [voteDisplayOption, setVoteDisplayOption] = useState("card");
  const [costPerVote, setCostPerVote] = useState(0);
  const [name, setName] = useState("");
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const FormValidationSchema = yup
    .object({
      name: yup.string().required("Name is required"),
      resultsVisibility: yup.mixed().required("Result visibility is required"),
      startDate: yup
        .date()
        .required("Start date is required")
        .min(today, "Start date must be today or later"),
      voteDisplayOption: yup
        .string()
        .oneOf(["card", "table", "bar_chart"])
        .required("Vote display option is required"),
      endDate: yup
        .date()
        .required("End date is required")
        .min(today, "Start date must be today or later"),
    })
    .required();

  const {
    register,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(FormValidationSchema),

    mode: "all",
  });

  useEffect(() => {
    reset(editItem);
  }, [editItem]);

  useEffect(() => {
    if (editItem) {
      reset({
        ...editItem,
        startDate: editItem.startDate
          ? new Date(editItem.startDate)
          : new Date(),
        voteDisplayOption: editItem.voteDisplayOption || "card",
        endDate: editItem.endDate ? new Date(editItem.endDate) : new Date(),
      });
      setStartDate(new Date(editItem.startDate));
      setEndDate(new Date(editItem.endDate));
      setImageUrl(editItem.imageUrl || "");
      setResultsVisibility(editEvent.resultsVisibility);
      setVoteDisplayOption(editEvent.voteDisplayOption || "card");
    }
  }, [editItem, editModal]);

  const onSubmit = async () => {
    const startDateString = startDate.toISOString().slice(0, 10);
    const endDateString = endDate.toISOString().slice(0, 10);

    const eventData = {
      title: name || editItem.title,
      costPerVote: costPerVote || editItem.costPerVote,
      imageUrl,
      startDate: startDateString || editItem.startDate,
      endDate: endDateString || editItem.endDate,
      hasBulkVoting: false,
      paymentMethods: ["1"],
      isActive: true,
      resultsVisibility: !resultsVisibility ? false : resultsVisibility,
      voteDisplayOption,
    };

    dispatch(editEvent({ eventId: editItem.id, event: eventData }));
    dispatch(toggleEditModal(false));
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(fetchEvents());
      dispatch(resetMessage());
    }
  }, [message]);

  const handleImageUrlChange = (url) => {
    setImageUrl(url);
  };

  return (
    <Modal
      title="Edit Event"
      activeModal={editModal}
      onClose={() => dispatch(toggleEditModal(false))}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
        <FormGroup error={errors.name}>
          <label htmlFor="name">Event name</label>
          <input
            type="text"
            defaultValue={editItem.title}
            placeholder="Enter Event name"
            className="form-control py-2"
            {...register("name")}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="amount">Cost per Vote</label>
          <input
            type="text"
            name="amount"
            id="amount"
            {...register("amount")}
            placeholder="Enter Event amount"
            className="form-control py-2"
            defaultValue={editItem.costPerVote}
            onChange={(e) => {
              setCostPerVote(e.target.value);
              set;
            }}
          />
        </FormGroup>

        <FormGroup>
          <ImageUploadInput
            label="image url"
            name="image"
            defaultValue={editItem.imageUrl}
            onImageUrlChange={handleImageUrlChange}
            imageUrl={editItem.imageUrl}
          />
        </FormGroup>
        <div className="my-6">
          <label htmlFor="resultsVisibility">Display Votes</label> {""}
          {""}
          <input
            type="checkbox"
            name="resultsVisibility"
            id="resultsVisibility"
            {...register("resultsVisibility")}
            defaultChecked={editEvent.resultsVisibility}
            onChange={(e) => {
              setResultsVisibility(e.target.checked);
            }}
          />
          {errors.resultsVisibility && (
            <p>{errors.resultsVisibility.message}</p>
          )}
        </div>
        <FormGroup error={errors.voteDisplayOption}>
          <label htmlFor="voteDisplayOption">Display Votes as</label>
          <select
            name="voteDisplayOption"
            {...register("voteDisplayOption")}
            className="form-control py-2"
            onChange={(e) => setVoteDisplayOption(e.target.value)}
          >
            <option value="card">Card</option>
            <option value="table">Table</option>
            <option value="bar_chart">Bar Chart</option>
          </select>
          {errors.voteDisplayOption && (
            <p className="text-red-500">{errors.voteDisplayOption.message}</p>
          )}
        </FormGroup>

        <div className="grid lg:grid-cols-2 gap-4 grid-cols-1">
          <FormGroup
            label="Start Date"
            id="default-picker"
            error={errors.startDate}
          >
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <Flatpickr
                  className="form-control py-2"
                  id="default-picker"
                  placeholder="yyyy, dd M"
                  value={startDate}
                  onChange={(date) => {
                    field.onChange(date);
                    setStartDate(date[0] || null);
                  }}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                  }}
                />
              )}
            />
          </FormGroup>
          <FormGroup
            label="End Date"
            id="default-picker2"
            error={errors.endDate}
          >
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <Flatpickr
                  className="form-control py-2"
                  id="default-picker2"
                  placeholder="yyyy, dd M"
                  value={endDate}
                  onChange={(date) => {
                    field.onChange(date);
                    setEndDate(date[0] || null);
                  }}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                  }}
                />
              )}
            />
          </FormGroup>
        </div>

        <div className="ltr:text-right rtl:text-left">
          <button className="btn btn-dark  text-center">Update</button>
        </div>
      </form>
    </Modal>
  );
};

export default EditEvent;
