import Card from "@/components/ui/Card";
import Dropdown from "@/components/ui/Dropdown";
import Icon from "@/components/ui/Icon";
import { protectedApi } from "@/store/api/api";
import { Menu } from "@headlessui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { updateEvent } from "./store";
import { LoadingIcon } from "@/components/Loading";

const EventGrid = ({
  event,
  showLaunchBtn,
  showOnlyCategoriesAction = false,
}) => {
  const { title, des, startDate, endDate, imageUrl, id, revenue } = event;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [start, setStart] = useState(new Date(startDate));
  const [end, setEnd] = useState(new Date(endDate));

  const daysLeft = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  const [isRestarting, setIsRestarting] = useState(false);

  const handleClick = (event) => {
    navigate(`/events/${event.id}`);
  };

  const handleRestart = async (event) => {
    setIsRestarting(true);
    await protectedApi
      .put(`/api/events/${event.id}/restart`)
      .then((res) => {
        alert("Event restarted successfully");
        window.location.reload();
      })
      .catch((err) => alert("something went wrong restarting event"))
      .finally(() => {
        setIsRestarting(false);
      });
  };

  const handleDelete = async (id) => {
    return await protectedApi.delete(`/api/events/${id}`);
  };

  const handleEndEvent = async (id) => {
    return await protectedApi.delete(`/api/events/${id}/end`);
  };

  const handleLaunch = async (id) => {
    return await protectedApi.put(`/api/events/${id}/launch`);
  };

  const launchEvent = (id) => {
    Swal.fire({
      title: `Are you sure you want to launch ${title}?`,
      icon: "success",
      showCancelButton: true,
      cancelButtonColor: "#ef4444",
      confirmButtonColor: "#22c55e",
      confirmButtonText: "Yes, launch it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          handleLaunch(id)
            .then((res) => {
              toast.success(`Event ${title} has been launched.`);
              setTimeout(() => {
                window.location.reload();
              }, 2500);
            })
            .catch((e) => toast.error(e.data?.message || e.message));
        }
      })
      .catch((e) => {
        console.log("eee", e);
        toast.error("something went wrong");
      });
  };

  const reloadPage = () =>
    setTimeout(() => {
      window.location.reload();
    }, 1500);

  const deleteEvent = (id) => {
    Swal.fire({
      title: `Are you sure you want to delete ${title}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ef4444",
      cancelButtonColor: "#22c55e",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          handleDelete(id)
            .then((res) => {
              toast.success(`Event ${title} has been deleted.`);
              reloadPage();
            })
            .catch((e) => toast.error(e.data?.message || e.message));
        }
      })
      .catch((e) => {
        console.log("eee", e);
        toast.error("something went wrong");
      });
  };

  const endEvent = (id) => {
    Swal.fire({
      text: `Are you sure you want to end ${title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ef4444",
      cancelButtonColor: "#22c55e",
      confirmButtonText: "Yes, end it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          handleEndEvent(id)
            .then((res) => {
              toast.success(`Event ${title} has been ended.`);
              reloadPage();
            })
            .catch((e) => toast.error(e.data?.message || e.message));
        }
      })
      .catch((e) => {
        console.log("eee", e);
        toast.error("something went wrong");
      });
  };

  return (
    <Card className="shadow-xl" bodyClass="p-0">
      <img
        src={imageUrl}
        alt=""
        className="object-cover w-full h-[14rem] rounded-t-md"
      />
      <div className="px-6 pb-8">
        <header className="flex justify-between items-end mt-5">
          <div className="flex space-x-4 items-center rtl:space-x-reverse">
            <div className="font-medium text-base leading-6">
              <div className="dark:text-slate-200 text-slate-900 text-lg leading-9">
                {title}
              </div>
            </div>
          </div>
          {!showOnlyCategoriesAction && (
            <div>
              <Dropdown
                classMenuItems=" w-[130px]"
                label={
                  <span className="text-lg inline-flex flex-col items-center justify-center h-8 w-8 rounded-full bg-gray-500-f7 dark:bg-slate-900 dark:text-slate-400">
                    <Icon icon="heroicons-outline:dots-vertical" />
                  </span>
                }
              >
                <div>
                  <Menu.Item onClick={() => handleClick(event)}>
                    <div
                      className="hover:bg-slate-900 dark:hover:bg-slate-600 dark:hover:bg-opacity-70 hover:text-white
                        w-full border-b border-b-gray-500 border-opacity-10   px-4 py-2 text-sm dark:text-slate-300  last:mb-0 cursor-pointer first:rounded-t last:rounded-b flex  space-x-2 items-center
                          capitalize rtl:space-x-reverse"
                    >
                      <span className="text-base">
                        <Icon icon="heroicons:eye" />
                      </span>
                      <span>View</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item onClick={() => dispatch(updateEvent(event))}>
                    <div
                      className="hover:bg-slate-900 dark:hover:bg-slate-600 dark:hover:bg-opacity-70 hover:text-white
                        w-full border-b border-b-gray-500 border-opacity-10   px-4 py-2 text-sm dark:text-slate-300  last:mb-0 cursor-pointer first:rounded-t last:rounded-b flex  space-x-2 items-center
                          capitalize rtl:space-x-reverse"
                    >
                      <span className="text-base">
                        <Icon icon="heroicons-outline:pencil-alt" />
                      </span>
                      <span>Edit</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item onClick={() => deleteEvent(event.id)}>
                    <div
                      className="hover:bg-slate-900 dark:hover:bg-slate-600 dark:hover:bg-opacity-70 hover:text-white
                        w-full border-b border-b-gray-500 border-opacity-10   px-4 py-2 text-sm dark:text-slate-300  last:mb-0 cursor-pointer first:rounded-t last:rounded-b flex  space-x-2 items-center
                          capitalize rtl:space-x-reverse"
                    >
                      <span className="text-base">
                        <Icon icon="heroicons-outline:trash" />
                      </span>
                      <span>Delete</span>
                    </div>
                  </Menu.Item>
                </div>
              </Dropdown>
            </div>
          )}
        </header>
        {/* description */}
        <div className="text-slate-600 dark:text-slate-400 text-sm py-2">
          {des}
        </div>
        {!showOnlyCategoriesAction && (
          <>
            <div className="my-2 mb-4">
              <p>Revenue: {revenue || 0}</p>
            </div>
            {/* <div className="flex justify-between rtl:space-x-reverse">
              <div>
                <span className="block date-label">Start date</span>
                <span className="block date-text">{startDate}</span>
              </div>
              <div>
                <span className="block date-label">End date</span>
                <span className="block date-text">{endDate}</span>
              </div>
            </div> */}
            <div className="grid grid-cols-2 gap-4 mt-5">
              {/* <p>{JSON.stringify(event)}</p> */}
              {!event.isPending && !event.isExpired ? (
                <div
                  onClick={() => endEvent(event.id)}
                  className="bg-danger-500 bg-opacity-[0.16] text-danger-500 text-xs font-normal px-2 py-1 rounded-full w-fit cursor-pointer hover:bg-opacity-10 hover:bg-danger-500 hover:text-danger transition-colors duration-300 ease-in-out hover:shadow-lg"
                >
                  {" "}
                  End event{" "}
                </div>
              ) : (
                <div className=" bg-opacity-[0.16]  text-xs font-normal px-2 py-1 rounded-full w-fit cursor-pointer hover:bg-opacity-10 hover:bg-danger-500 hover:text-danger transition-colors duration-300 ease-in-out hover:shadow-lg"></div>
              )}
              {/* total date */}
              {!isNaN(end) && (
                <div className="ltr:text-right rtl:text-left">
                  <span className="inline-flex items-center space-x-1 bg-danger-500 bg-opacity-[0.16] text-danger-500 text-xs font-normal px-2 py-1 rounded-full rtl:space-x-reverse">
                    <span>
                      {" "}
                      <Icon icon="heroicons-outline:clock" />
                    </span>
                    <span>
                      {daysLeft < 0
                        ? "Event ended"
                        : daysLeft === 0
                        ? "Last day"
                        : daysLeft === 1
                        ? `${daysLeft} day left`
                        : `${daysLeft} days left`}
                    </span>
                  </span>
                </div>
              )}
            </div>
            {!event.isExpired && !event.isPending && (
              <div className="w-full mt-5">
                <button
                  type="button"
                  disabled={isRestarting}
                  onClick={() => handleRestart(event)}
                  className="bg-blue-500 px-4 py-2 text-white rounded-md mt-4 hover:bg-blue-600 transition-colors duration-300 ease-in-out w-full flex items-center justify-center gap-4 shadow-lg"
                >
                  <span>
                    {!isRestarting ? "Restart Event" : <LoadingIcon />}
                  </span>
                </button>
              </div>
            )}
          </>
        )}
        <div className="flex justify-center gap-6 mt-4 w-full">
          {!showOnlyCategoriesAction && (
            <button
              type="button"
              onClick={() => dispatch(updateEvent(event))}
              className="bg-transparent px-4 py-2 text-green-500 rounded-md mt-4 hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out w-full border border-green-500 flex items-center justify-center gap-4 shadow-lg"
            >
              <span>Edit</span>
            </button>
          )}
          <button
            type="button"
            onClick={() => handleClick(event)}
            className="bg-blue-500 px-4 py-2 text-white rounded-md mt-4 hover:bg-blue-600 transition-colors duration-300 ease-in-out w-full flex items-center justify-center gap-4 shadow-lg"
          >
            <span>Categories</span>
          </button>
        </div>
        {showLaunchBtn && (
          <div className="flex justify-center gap-6 mt-4 w-full">
            <button
              type="button"
              onClick={() => launchEvent(event.id)}
              className="bg-transparent px-4 py-2 text-green-500 rounded-md mt-4 hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out w-full border border-green-500 flex items-center justify-center gap-4 shadow-lg"
            >
              <span>Launch Event</span>
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default EventGrid;
