// import {
//   AdminMetrics,
// } from "@/components/partials/widget/chart/group-chart5";
import Card from "@/components/ui/Card";
import moment from "moment";
import { useState } from "react";

import Mainuser from "@/assets/images/all-img/main-user.png";
import HomeBredCurbs from "@/pages/dashboard/HomeBredCurbs";
import { protectedApi } from "@/store/api/api";
import { useEffect } from "react";
import { AdminMetrics } from "@/components/partials/widget/chart/group-chart5";

const Greeting = () => {
  const hour = new Date().getHours();
  let greeting;

  if (hour < 12) {
    greeting = "Good morning";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  return greeting;
};

const SuperAdmin = () => {
  const [metrics, setMetrics] = useState({
    pendingEventsCount: 0,
    activeEventsCount: 0,
  });

  const [events, setEvents] = useState([]);

  useEffect(() => {
    protectedApi
      .get("/api/dashboard/admin/metrics")
      .then((res) => setMetrics(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    protectedApi
      .get("/api/events/all-active")
      .then((res) => setEvents(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="space-y-5">
      <HomeBredCurbs title="Dashboard" showDatepicker={false} />
      <Card>
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 place-content-center">
          <div className="flex space-x-4 h-full items-center rtl:space-x-reverse">
            <div className="flex-none">
              <div className="h-20 w-20 rounded-full">
                <img src={Mainuser} alt="" className="w-full h-full" />
              </div>
            </div>
            <div className="flex-1">
              <h4 className="text-xl font-medium mb-2">
                <span className="block font-light">{Greeting()},</span>
                <span className="block">Admin</span>
              </h4>
              <p className="text-sm dark:text-slate-300">
                Welcome to KampaignLive
              </p>
            </div>
          </div>
          <AdminMetrics
            activeEventsCount={metrics.activeEventsCount || 0}
            pendingEventsCount={metrics.activeEventsCount || 0}
            data={metrics}
          />
        </div>
      </Card>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <div className="space-y-5 bank-table">
            {events.length > 0 && (
              <Card>
                <div className="flex justify-between mb-10">
                  <h4 className="card-title mb-7">Super Admin Active Events</h4>
                </div>
                <DataTable data={events} />
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function DataTable({ data }) {
  return (
    <div className="overflow-x-auto pb-8">
      <table className="min-w-full divide-slate-100 table-fixed dark:divide-slate-700">
        <thead className="border-t border-slate-100 dark:border-slate-800">
          <tr className="">
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Event Title
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Revenue
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              End date
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700">
          {data.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.revenue}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item.endDate).format("DD-MM-YYYY, hh:mm:ss a")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SuperAdmin;
