import { useEffect, useState } from "react";
import Modal from "@/components/ui/Modal";
import { useSelector, useDispatch } from "react-redux";
import { toggleAddModal } from "./store";
import Textinput from "@/components/ui/Textinput";
import Flatpickr from "react-flatpickr";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormGroup from "@/components/ui/FormGroup";
import { fetchEvents } from "@/utils/eventService";
import { ImageUploadInput } from "./ImageUpload";
import { resetMessage } from "./store";
import { toast } from "react-toastify";
import PulsatingLoder from "@/components/PulsatingLoder";
import { LoadingIcon } from "@/components/Loading";

const AddEvent = ({ onFormSubmit }) => {
  const { openEventModal, message } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [voteDisplayOption, setVoteDisplayOption] = useState("card");

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDateString = startDate
    ? startDate.toISOString().split("T")[0]
    : "";
  const endDateString = endDate ? endDate.toISOString().split("T")[0] : "";

  const FormValidationSchema = yup
    .object({
      title: yup.string().required("Title is required"),
      eventType: yup.mixed().required("Event type is required"),
      amount: yup.number().required("Amount is required"),
      image: yup.string().required("Image is required"),
      resultsVisibility: yup.mixed().required("Result visibility is required"),
      voteDisplayOption: yup
        .string()
        .oneOf(["card", "table", "bar_chart"])
        .required("Vote display option is required"),
      startDate: yup
        .date()
        .required("Start date is required")
        .min(today, "Start date must be today or later"),
      endDate: yup
        .date()
        .required("End date is required")
        .min(today, "Start date must be today or later"),
    })
    .required();

  const {
    register,
    control,
    reset,
    formState: { errors },
    setError,
    getValues,
    // handleSubmit,
  } = useForm({
    resolver: yupResolver(FormValidationSchema),
    mode: "all",
    defaultValues: {
      resultsVisibility: true,
    },
  });

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      title: e.target.title.value,
      costPerVote: +e.target.amount.value,
      imageUrl: imageUrl,
      startDate: startDateString,
      endDate: endDateString,
      numberOfCategories: 1,
      paymentMethods: ["1"],
      resultsVisibility: e.target.resultsVisibility.checked,
      voteDisplayOption,
    };

    onFormSubmit(formData);
    setIsLoading(false);
    reset();
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(fetchEvents());
      dispatch(toggleAddModal(false));
      dispatch(resetMessage());
    }
  }, [message]);

  const handleImageUrlChange = (url) => {
    setImageUrl(url);
  };

  return (
    <div>
      <Modal
        title="Create Event"
        labelclassName="btn-outline-dark"
        activeModal={openEventModal}
        onClose={() => {
          dispatch(toggleAddModal(false));
          dispatch(fetchEvents());
        }}
      >
        <>
          {isLoading && <PulsatingLoder />}
          <form onSubmit={handleSubmitEvent} className="space-y-4 ">
            <Textinput
              name="title"
              label="Event Name"
              placeholder="Event Name"
              required
              register={register}
              error={errors.title}
            />

            <Textinput
              name="amount"
              required
              type="number"
              min="0.5"
              step="0.5"
              label="Event Amount"
              placeholder="Event Amount"
              register={register}
              error={errors.title}
            />

            <div>
              <ImageUploadInput
                label="image url"
                name="image"
                required
                register={register}
                onImageUrlChange={handleImageUrlChange}
                error={errors.image}
                setError={setError}
              />
            </div>
            <div className="my-6">
              <label htmlFor="resultsVisibility">Display Votes</label> {""}
              {""}
              <input
                type="checkbox"
                name="resultsVisibility"
                id="resultsVisibility"
                {...register("resultsVisibility")}
                defaultChecked={getValues("resultsVisibility")}
              />
              {errors.resultsVisibility && (
                <p>{errors.resultsVisibility.message}</p>
              )}
            </div>
            <div className="">
              <FormGroup error={errors.voteDisplayOption}>
                <label htmlFor="voteDisplayOption">Display Votes as</label>
                <select
                  name="voteDisplayOption"
                  {...register("voteDisplayOption")}
                  className="form-control py-2"
                  onChange={(e) => setVoteDisplayOption(e.target.value)}
                >
                  <option value="card">Card</option>
                  <option value="table">Table</option>
                  <option value="bar_chart">Bar Chart</option>
                </select>
                {errors.voteDisplayOption && (
                  <p className="text-red-500">
                    {errors.voteDisplayOption.message}
                  </p>
                )}
              </FormGroup>
            </div>
            <div className="grid lg:grid-cols-2 gap-4 grid-cols-1">
              <FormGroup
                label="Start Date"
                id="default-picker"
                error={errors.startDate}
              >
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      className="form-control py-2"
                      id="default-picker"
                      placeholder="yyyy, dd M"
                      value={startDate}
                      onChange={(date) => {
                        setStartDate(date[0] || null);
                        field.onChange(date[0] || null);
                      }}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                      }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup
                label="End Date"
                id="default-picker2"
                error={errors.endDate}
              >
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      className="form-control py-2"
                      id="default-picker2"
                      placeholder="yyyy, dd M"
                      value={endDate}
                      onChange={(date) => {
                        setEndDate(date[0] || null);
                        field.onChange(date[0] || null);
                      }}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                      }}
                    />
                  )}
                />
              </FormGroup>
            </div>

            <div className="ltr:text-right rtl:text-left">
              <button
                disabled={!imageUrl}
                className="btn btn-dark  text-center"
                type="submit"
              >
                <span className="flex justify-between gap-x-4">
                  {isLoading ? "Saving..." : "Save"}
                  {isLoading && <LoadingIcon />}
                </span>
              </button>
            </div>
          </form>
        </>
      </Modal>
    </div>
  );
};

export default AddEvent;
