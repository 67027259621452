import PlacesAutocomplete from "@/components/PlacesAutoComplete";
import { TextInput } from "@/components/forms/FormElements";
import { useAuth } from "@/contexts/AuthContext";
import { firebaseDb } from "@/pages/auth/common/firebaseConfig";
import { doc, getDoc, setDoc, collection, Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import BackButton from "../components/backButton";

const EventTicketForm = () => {
  const { user } = useAuth();
  const { id: eventId } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    eventName: "",
    tickets: [{ type: "", price: 0, quantity: 0 }],
    venueName: "",
    venueCity: "",
    longitude: "",
    latitude: "",
    eventDate: "",
    eventStartTime: "",
    eventEndTime: "",
    eventImage: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const isEditing = eventId != null;

  const validationSchema = yup.object({
    eventName: yup.string().required("Event name is required"),
    tickets: yup.array().of(
      yup.object({
        type: yup.string().required("Ticket type is required"),
        price: yup.number().required("Ticket price is required").min(0),
        quantity: yup.number().required("Ticket quantity is required").min(1),
      })
    ),
    venueName: yup.string().required("Venue name is required"),
    venueCity: yup.string().required("Venue city is required"),
    eventDate: yup.date().required("Event date is required"),
    eventStartTime: yup.string().required("Event start time is required"),
    eventEndTime: yup.string().required("Event end time is required"),
  });

  useEffect(() => {
    if (isEditing) {
      const fetchEvent = async () => {
        try {
          const docRef = doc(firebaseDb, "ticket-events", eventId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let data = docSnap.data();
            const eventData = {
              id: docSnap.id,
              ...data,
              eventDate: new Date(data.eventDate).toISOString().split("T")[0],
              eventStartTime: new Date(data.eventStartTime)
                .toISOString()
                .split("T")[1]
                .substr(0, 5),
              eventEndTime: new Date(data.eventEndTime)
                .toISOString()
                .split("T")[1]
                .substr(0, 5),
            };

            setInitialValues(eventData);
            if (eventData.eventImage) {
              setImagePreview(eventData.eventImage);
            }
          } else {
            toast.error("No such event found");
          }
        } catch (error) {
          console.log(error)
          toast.error("Error fetching event details");
        }
      };
      fetchEvent();
    }
  }, [eventId, isEditing]);

  const saveEvent = async (values) => {
    try {
      const eventsCollection = collection(firebaseDb, "ticket-events");
      const docRef = isEditing
        ? doc(eventsCollection, eventId)
        : doc(eventsCollection);
      const storage = getStorage();

      let imageUrl = initialValues.eventImage;
      if (values.eventImage && values.eventImage !== initialValues.eventImage) {
        const imageRef = ref(
          storage,
          `ticket-events/${docRef.id}/${values.eventImage.name}`
        );
        const snapshot = await uploadBytes(imageRef, values.eventImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const eventDate = Timestamp.fromDate(
        new Date(values.eventDate)
      ).toMillis();
      const eventStartTime = Timestamp.fromDate(
        new Date(`${values.eventDate}T${values.eventStartTime}`)
      ).toMillis();
      const eventEndTime = Timestamp.fromDate(
        new Date(`${values.eventDate}T${values.eventEndTime}`)
      ).toMillis();

      const data = {
        ...values,
        eventDate,
        eventStartTime,
        eventEndTime,
        eventImage: imageUrl,
        organiserId: user.uid,
      };

      await setDoc(docRef, data);
      toast.success("Event saved successfully");
      navigate(`/tickets`);
    } catch (error) {
      console.log(error);
      toast.error("Error saving event");
    }
  };

  return (
    <div className="space-y-5">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">
          {isEditing ? "Edit Event" : "Create Ticket Event"}
        </h1>
        <BackButton />
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex max-w-6xl mx-auto py-16 space-x-6">
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                await saveEvent(values);
                actions.setSubmitting(false);
                if (!isEditing) {
                  actions.resetForm();
                }
              }}
            >
              {({ values, setFieldValue, isValid, isSubmitting }) => (
                <Form className="space-y-6">
                  <TextInput
                    name="eventName"
                    label="Event Name"
                    type="text"
                    required
                  />
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Event Image
                    </label>
                    <input
                      name="eventImage"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.currentTarget.files[0];
                        setFieldValue("eventImage", file);
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setImagePreview(reader.result);
                          };
                          reader.readAsDataURL(file);
                        } else {
                          setImagePreview(null);
                        }
                      }}
                      className="mt-1 block w-full py-2"
                    />
                  </div>

                  <FieldArray
                    name="tickets"
                    render={(arrayHelpers) => (
                      <div className="mb-1">
                        {values.tickets.map((ticket, index) => (
                          <div
                            key={index}
                            className="flex mb-2 space-x-5 items-center"
                          >
                            <TextInput
                              name={`tickets[${index}].type`}
                              label="Ticket Type"
                              type="text"
                              required
                            />
                            <TextInput
                              name={`tickets[${index}].price`}
                              label="Price"
                              type="number"
                              required
                            />
                            <TextInput
                              name={`tickets[${index}].quantity`}
                              label="Quantity Available"
                              type="number"
                              required
                            />
                            <div className="w-32">
                              <button
                                type="button"
                                className="text-red-500 mt-2 w-32"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove Ticket
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className=" text-blue-500"
                          onClick={() =>
                            arrayHelpers.push({
                              type: "",
                              price: 0,
                              quantity: 0,
                            })
                          }
                        >
                          Add Ticket Type
                        </button>
                      </div>
                    )}
                  />
                  <div className="mt-16">
                    <label className="block text-sm font-medium text-gray-700">
                      Venue Name
                    </label>
                    <PlacesAutocomplete setFieldValue={setFieldValue} />
                  </div>
                  <TextInput
                    name="venueCity"
                    label="Venue City"
                    type="text"
                    required
                  />
                  <TextInput
                    name="eventDate"
                    label="Event Date"
                    type="date"
                    required
                  />
                  <TextInput
                    name="eventStartTime"
                    label="Event Start Time"
                    type="time"
                    required
                  />
                  <TextInput
                    name="eventEndTime"
                    label="Event End Time"
                    type="time"
                    required
                  />
                  <button
                    type="submit"
                    className={`w-full py-3 text-center font-bold px-4 rounded ${
                      !isValid || isSubmitting
                        ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                        : "bg-blue-600 text-white hover:bg-blue-700"
                    }`}
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          {imagePreview && (
            <div className="w-1/3">
              <h2 className="text-lg font-bold">Image Preview</h2>
              <img
                src={imagePreview}
                alt="Event Preview"
                className="mt-4 rounded-lg shadow-lg"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventTicketForm;
