import Card from "@/components/ui/Card";
import moment from "moment";
import { useState } from "react";

import Mainuser from "@/assets/images/all-img/main-user.png";
import HomeBredCurbs from "@/pages/dashboard/HomeBredCurbs";
import { protectedApi } from "@/store/api/api";
import { useEffect } from "react";
import { AdminMetrics } from "@/components/partials/widget/chart/group-chart5";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import SearchModal from "@/components/partials/header/Tools/SearchModal";
import TransactionsTable from "../app/transactions-table";
import { toast } from "react-toastify";

const Greeting = () => {
  const hour = new Date().getHours();
  let greeting;

  if (hour < 12) {
    greeting = "Good morning";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  return greeting;
};

const handleLaunch = async (id) => {
  return await protectedApi.put(`/api/events/admin/${id}/launch`);
};

const launchEvent = (id, title) => {
  Swal.fire({
    title: `Are you sure you want to launch ${title}?`,
    icon: "success",
    showCancelButton: true,
    cancelButtonColor: "#ef4444",
    confirmButtonColor: "#22c55e",
    confirmButtonText: "Yes, launch it!",
  })
    .then(async (result) => {
      if (result.isConfirmed) {
        handleLaunch(id)
          .then((res) => {
            toast.success(`Event ${title} has been launched.`);
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          })
          .catch((e) => toast.error(e.data?.message || e.message));
      }
    })
    .catch((e) => {
      console.log("eee", e);
      toast.error("something went wrong");
    });
};

const PartnerDashboard = () => {
  const [metrics, setMetrics] = useState({
    pendingEventsCount: 0,
    activeEventsCount: 0,
  });

  const [events, setEvents] = useState([]);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [transactionSearchResults, setTransactionSearchResults] = useState([]);

  useEffect(() => {
    protectedApi
      .get("/api/dashboard/admin/metrics")
      .then((res) => setMetrics(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    protectedApi
      .get("/api/events/all-active")
      .then((res) => setEvents(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    protectedApi
      .get("/api/events/all-pending")
      .then((res) => setPendingEvents(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    protectedApi
      .get("/api/events/launch")
      .then((res) => setPendingEvents(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const handleNoTransactions = (data) => {
    if (data.length && data.length == 0) {
      toast.success("No transactions found");
    }
  };

  const handleTransactionSearch = (reference, queryType) => {
    protectedApi
      .get(`/api/payments/${reference}`)
      .then((res) => {
        console.log(res.data);
        setTransactionSearchResults(res.data.data);
        handleNoTransactions(res.data?.data);
      })
      .catch((err) => console.log("err", err));
  };

  const handleTransactionSearchByPhone = (phone) => {
    protectedApi
      .get(`/api/payments/admin/transactions/phoneNumbers/${phone}`)
      .then((res) => {
        console.log(res.data);
        setTransactionSearchResults(res.data.data);
        handleNoTransactions(res.data?.data);
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <div className="space-y-5">
      <HomeBredCurbs title="Dashboard" showDatepicker={false} />
      <Card>
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 place-content-center">
          <div className="flex space-x-4 h-full items-center rtl:space-x-reverse">
            <div className="flex-none">
              <div className="h-20 w-20 rounded-full">
                <img src={Mainuser} alt="" className="w-full h-full" />
              </div>
            </div>
            <div className="flex-1">
              <h4 className="text-xl font-medium mb-2">
                <span className="block font-light">{Greeting()},</span>
                <span className="block">Partner</span>
              </h4>
              <p className="text-sm dark:text-slate-300">
                Welcome to KampaignLive
              </p>
            </div>
          </div>
          <AdminMetrics
            activeEventsCount={metrics.activeEventsCount || 0}
            pendingEventsCount={metrics.activeEventsCount || 0}
            data={metrics}
          />
        </div>
      </Card>
      <Card>
        <div className="flex space-x-5">
          <SearchModal
            onSearchSubmit={handleTransactionSearchByPhone}
            placeholder="Search for payment by phone number..e.g (0541212123)..."
          />
          <SearchModal
            onSearchSubmit={handleTransactionSearch}
            placeholder="Search for payment by reference..."
          />
        </div>
      </Card>
      {transactionSearchResults.length > 0 && (
        <div className="mt-9">
          <Card>
            <div className="flex justify-between">
              <h5>Transactions Search Results</h5>
              <div className="w-44">
                <button
                  type="button"
                  className="bg-blue-600 px-2 py-2 text-white rounded-md w-full hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out border border-green-500 flex items-center justify-center gap-4"
                  onClick={() => setTransactionSearchResults([])}
                >
                  <span>Hide Results</span>
                </button>
              </div>
            </div>
            <div className="mt-5 mb-10">
              <TransactionsTable
                data={transactionSearchResults}
                showEventNames={true}
              />
            </div>
          </Card>
        </div>
      )}
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <div className="space-y-5 bank-table">
            {events.length > 0 && (
              <Card>
                <div className="flex justify-between mb-10">
                  <h4 className="card-title mb-7">All Active Events</h4>
                </div>
                <DataTable data={events} showDetailsAction={true} />
              </Card>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <div className="space-y-5 bank-table">
            {pendingEvents.length > 0 && (
              <Card>
                <div className="flex justify-between mb-10">
                  <h4 className="card-title mb-7">All Pending Events</h4>
                </div>
                <DataTable data={pendingEvents} showLaunchAction={true} />
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function DataTable({ data, showLaunchAction, showDetailsAction }) {
  const navigate = useNavigate();
  return (
    <div className="overflow-x-auto pb-8">
      <table className="min-w-full divide-slate-100 table-fixed dark:divide-slate-700">
        <thead className="border-t border-slate-100 dark:border-slate-800">
          <tr className="">
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Event Title
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Revenue
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              End date
            </th>
            {showLaunchAction && (
              <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                Actions
              </th>
            )}
            {showDetailsAction && (
              <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700">
          {data.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.revenue}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item.endDate).format("DD-MM-YYYY, hh:mm:ss a")}
              </td>
              {showLaunchAction && (
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    type="button"
                    className="bg-green-500 px-4 py-2 text-white rounded-md w-full hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out border border-green-500 flex items-center justify-center gap-4"
                    onClick={() => launchEvent(item.id)}
                  >
                    <span>Launch</span>
                  </button>
                </td>
              )}

              {showDetailsAction && (
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    type="button"
                    className="bg-green-500 px-4 py-2 text-white rounded-md w-full hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out border border-green-500 flex items-center justify-center gap-4"
                    onClick={() => navigate(`/events/${item.id}`)}
                  >
                    <span>View Details</span>
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PartnerDashboard;
