import Loading from "@/components/Loading";
import { auth } from "@/pages/auth/common/firebaseConfig";
import { createContext, useContext, useEffect, useState } from "react";
import {
  useIdToken,
  useSignInWithEmailAndPassword,
  useSignOut,
} from "react-firebase-hooks/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, loading, error] = useIdToken(auth);
  const [signInWithEmailAndPassword, loggedInUser, loginLoading, loginError] =
    useSignInWithEmailAndPassword(auth);
  const [signOut] = useSignOut(auth);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [token, setToken] = useState(null);

  const saveToken = (token) => {
    setToken(token);
    localStorage.setItem("token", JSON.stringify(token));
  };

  const removeToken = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  useEffect(() => {
    if (!loading && user) {
      user.getIdTokenResult().then((idTokenResult) => {
        saveToken(idTokenResult.token);
        setIsAdmin(idTokenResult.claims?.admin || false);
        setIsPartner(idTokenResult.claims?.partner || false);
      });
    } else if (!loading && !user) {
      removeToken();
    }
  }, [user]);

  if (loginError) console.log("bad", loginError);

  const login = async (email, password) => {
    try {
      return await signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.log("bad", loginError);
      throw new Error("login failed", error);
    }
  };

  const logout = async () => {
    await signOut();
    removeToken();
    window.location.href = "/auth/login";
  };

  const value = { loading, user, login, logout, token, isAdmin, isPartner };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
