import React from "react";

const CurrencyFormatter = ({ value, currency = "GHS", locale = "en-GH" }) => {
  const formattedCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
  }).format(value);

  const addSpaceCurrency = formattedCurrency.replace(/(\D+)(\d+)/, "$1 $2");

  return <span>{addSpaceCurrency}</span>;
};

export default CurrencyFormatter;
