import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useWidth from "@/hooks/useWidth";
import Button from "@/components/ui/Button";
import EventGrid from "./EventGrid";
import EventList from "./EventList";
import { toggleAddModal } from "./store";
import AddEvent from "./AddEvent";
import { ToastContainer, toast } from "react-toastify";
import EditEvent from "./EditEvent";
import { saveNewEvent } from "../../../utils/eventService";
import Loading from "@/components/Loading";
import { protectedApi } from "@/store/api/api";
import NotFound from "@/pages/components/not-found";

const EventPostPage = () => {
  const [filler, setfiller] = useState("grid");
  const { width, breakpoints } = useWidth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEventsLoading, setIsEventsLoading] = useState(false);

  const { events } = useSelector((state) => state.event);
  const dispatch = useDispatch();

  const hideAddModal = () => dispatch(toggleAddModal(false));

  const [expiredEvents, setExpiredEvents] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [eventsUpdated, setEventsUpdated] = useState(false);

  const reloadPage = () =>
    setTimeout(() => {
      window.location.reload();
    }, 1500);

  const handleNewEventFormSubmit = async (formData) => {
    try {
      const res = await saveNewEvent(formData);
      hideAddModal();
      toast.success(res.message);
      setEventsUpdated(true);
    } catch (error) {
      hideAddModal();
      toast.error(error.response.data.message);
    }
  };

  const getAllEvents = () => protectedApi.get("/api/events/mine");

  useEffect(() => {
    setIsEventsLoading(true);

    // protectedApi
    //   .get(`/api/events/organiser/my-events`)
    //   .then((res) => console.log("first", res.data))
    //   .catch((err) => console.error("err f", err));

    getAllEvents()
      .then((res) => {
        const events = res.data.data;
        setActiveEvents(events.activeEvents);
        setPendingEvents(events.pendingEvents);
        setExpiredEvents(events.expiredEvents);
      })
      .catch((e) => toast.error(e.response?.data?.message || e.message))
      .finally(() => setIsEventsLoading(false));
  }, [eventsUpdated]);

  useEffect(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsLoaded(false);
    }, 1500);
  }, [filler]);

  const showListViewButton =
    width >= breakpoints.md && events && events.length > 0;
  const showGridViewButton =
    width >= breakpoints.md && events && events.length > 0;

  if (isEventsLoading) return <Loading />;

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-wrap justify-between items-center mb-4">
        <h4 className="font-medium lg:text-2xl text-xl capitalize text-slate-900 inline-block ltr:pr-4 rtl:pl-4">
          Organiser's Events
        </h4>
        <div
          className={`${
            width < breakpoints.md ? "space-x-rb" : ""
          } md:flex md:space-x-4 md:justify-end items-center rtl:space-x-reverse mt-3`}
        >
          {showListViewButton && (
            <Button
              icon="heroicons:list-bullet"
              text="List view"
              disabled={isLoaded}
              className={`${
                filler === "list"
                  ? "bg-slate-900 dark:bg-slate-700  text-white"
                  : " bg-white dark:bg-slate-800 dark:text-slate-300"
              }   h-min text-sm font-normal`}
              iconClass=" text-lg"
              onClick={() => setfiller("list")}
            />
          )}
          {showGridViewButton && (
            <Button
              icon="heroicons-outline:view-grid"
              text="Grid view"
              disabled={isLoaded}
              className={`${
                filler === "grid"
                  ? "bg-slate-900 dark:bg-slate-700 text-white"
                  : " bg-white dark:bg-slate-800 dark:text-slate-300"
              }   h-min text-sm font-normal`}
              iconClass=" text-lg"
              onClick={() => setfiller("grid")}
            />
          )}
          <Button
            icon="heroicons-outline:plus"
            text="Create Event"
            className="btn-dark dark:bg-slate-800 h-min text-sm font-normal ml-2 bg-green-500 text-white hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out"
            iconClass=" text-lg"
            onClick={() => dispatch(toggleAddModal(true))}
          />
        </div>
      </div>
      {filler === "grid" && !isEventsLoading && (
        <>
          <div className="my-8">
            <h6 className="mb-7">Active Events</h6>
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
              {activeEvents.length > 0 ? (
                activeEvents?.map((event, eventIndex) => (
                  <EventGrid event={event} key={eventIndex} />
                ))
              ) : (
                <p className="text-bold">No active events found</p>
              )}
            </div>
          </div>
          <div className="my-8">
            <h6 className="mb-7">Pending Events</h6>
            {pendingEvents.length > 0 ? (
              <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
                {pendingEvents.map((event, eventIndex) => (
                  <EventGrid
                    event={event}
                    key={eventIndex}
                    showLaunchBtn={true}
                  />
                ))}
              </div>
            ) : (
              <NotFound model={"pending events"} />
            )}
          </div>
          <div className="my-8">
            <h6 className="mb-7">Expired Events</h6>
            {expiredEvents.length > 0 ? (
              <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
                {expiredEvents.map((event, eventIndex) => (
                  <EventGrid
                    event={event}
                    key={eventIndex}
                    showLaunchBtn={true}
                  />
                ))}
              </div>
            ) : (
              <NotFound model={"expired events"} />
            )}
          </div>
        </>
      )}
      {filler === "list" && !isEventsLoading && (
        <div>
          <div className="my-8">
            <h6 className="mb-7">Active Events</h6>
            <EventList events={activeEvents} />
          </div>
          <div className="my-8">
            <h6 className="mb-7">Pending Events</h6>
            <EventList events={pendingEvents} />
          </div>
          <div className="my-8">
            <h6 className="mb-7">Expired Events</h6>
            <EventList events={expiredEvents} />
          </div>
        </div>
      )}
      <AddEvent onFormSubmit={handleNewEventFormSubmit} />
      <EditEvent />
    </div>
  );
};

export default EventPostPage;
