import { protectedApi } from "@/store/api/api";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";

export default function TransactionsTable({ data, showEventNames = false }) {
  const [disabledButtons, setDisabledButtons] = useState({});

  useEffect(() => {
    const storedDisabledButtons =
      JSON.parse(localStorage.getItem("disabledButtons")) || {};
    const currentTime = new Date().getTime();
    const updatedDisabledButtons = {};

    for (const [id, expiry] of Object.entries(storedDisabledButtons)) {
      if (currentTime < expiry) {
        updatedDisabledButtons[id] = expiry;
      }
    }

    setDisabledButtons(updatedDisabledButtons);

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const newDisabledButtons = {};

      for (const [id, expiry] of Object.entries(updatedDisabledButtons)) {
        if (currentTime < expiry) {
          newDisabledButtons[id] = expiry;
        }
      }

      setDisabledButtons(newDisabledButtons);
      localStorage.setItem(
        "disabledButtons",
        JSON.stringify(newDisabledButtons)
      );
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const handleVerify = async (transactionId, paymentGateway) => {
    try {
      const response = await protectedApi.put(
        `/api/payments/${transactionId}/verify`,
        {
          reference: transactionId,
          processedBy: paymentGateway,
        }
      );

      if (response.status === 200) {
        const expiryTime = new Date().getTime() + 5 * 60 * 1000; // 5 minutes from now
        setDisabledButtons((prev) => ({
          ...prev,
          [transactionId]: expiryTime,
        }));
        localStorage.setItem(
          "disabledButtons",
          JSON.stringify({
            ...disabledButtons,
            [transactionId]: expiryTime,
          })
        );
      }
    } catch (error) {
      console.error("Verification failed", error);
    }
  };

  const isButtonDisabled = (transactionId) => {
    const currentTime = new Date().getTime();
    return (
      disabledButtons[transactionId] &&
      currentTime < disabledButtons[transactionId]
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-slate-100 table-fixed dark:divide-slate-700">
        <thead className="border-t border-slate-100 dark:border-slate-800">
          <tr className="">
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Id
            </th>
            {showEventNames && (
              <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                Event
              </th>
            )}
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Nominee
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Amount
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Vote Added
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Date Paids
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Paid By
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700">
          {data.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
              {showEventNames && (
                <td className="px-6 py-4 whitespace-nowrap">
                  {item.event || item.eventTitle || ""}
                </td>
              )}
              <td className="px-6 py-4 whitespace-nowrap">{item.optionText}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.amount.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.voteAdded
                  ? "Successful"
                  : item.verified
                  ? "Failed"
                  : "Pending"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item.dateCreatedMillis).format(
                  "DD-MM-YYYY, hh:mm:ss a"
                )}
              </td>
              <td className="pl-4">
                {item.email || item.phoneNumber || item.customerName || ""}
              </td>
              {item.voteAdded || item.verified ? (
                <td className="pl-4">{item.verified ? "verified" : ""}</td>
              ) : (
                <td className="pl-4">
                  <button
                    type="button"
                    className={classNames(
                      "px-4 py-2 rounded-md w-full transition-colors duration-300 ease-in-out border flex items-center justify-center gap-4",
                      {
                        "bg-green-500 text-white hover:bg-green-600 border-green-500":
                          !isButtonDisabled(item.id),
                        "bg-gray-400 text-gray-700 cursor-not-allowed border-gray-400":
                          isButtonDisabled(item.id),
                      }
                    )}
                    onClick={() => handleVerify(item.id, item.processedBy)}
                    disabled={isButtonDisabled(item.id)}
                  >
                    <span className="text-sm">
                      {isButtonDisabled(item.id) ? `Verifying` : `Verify`}
                    </span>
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
