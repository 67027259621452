import moment from "moment";
import Dropdown from "@/components/ui/Dropdown";
import Icon from "@/components/ui/Icon";
import { Menu } from "@headlessui/react";

export default function PayoutsDataTable({ data = [], tableActions = [] }) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-slate-100 table-fixed dark:divide-slate-700">
        <thead className="border-t border-slate-100 dark:border-slate-800">
          <tr className="">
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Organiser
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Amount
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Status
            </th>
            <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
              Date Created
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700">
          {data.map((item) => (
            <tr key={item.userId}>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.organiserName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.amount.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{item.status}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item.dateCreated).format("DD-MM-YYYY, hh:mm:ss a")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Actions({ tableActions }) {
  return (
    <Dropdown
      classMenuItems="right-0 w-[140px] top-[110%] "
      label={
        <span className="text-xl text-center block w-full">
          <Icon icon="heroicons-outline:dots-vertical" />
        </span>
      }
    >
      <div className="divide-y divide-slate-100 dark:divide-slate-800">
        {tableActions.map((item, i) => (
          <Menu.Item key={i}>
            <div
              className={`
                
                  ${
                    item.name === "delete"
                      ? "bg-danger-500 text-danger-500 bg-opacity-30   hover:bg-opacity-100 hover:text-white"
                      : "hover:bg-slate-900 hover:text-white dark:hover:bg-slate-600 dark:hover:bg-opacity-50"
                  }
                   w-full border-b border-b-gray-500 border-opacity-10 px-4 py-2 text-sm  last:mb-0 cursor-pointer 
                   first:rounded-t last:rounded-b flex  space-x-2 items-center rtl:space-x-reverse `}
            >
              <span className="text-base">
                <Icon icon={item.icon} />
              </span>
              <span>{item.name}</span>
            </div>
          </Menu.Item>
        ))}
      </div>
    </Dropdown>
  );
}
