export const menuItems = [
  // {
  //   isHeadr: true,
  //   title: "menu",
  // },

  {
    title: "Home",
    icon: "heroicons-outline:home",
    isAdmin: false,
    link: "",
  },
  {
    title: "Events",
    icon: "heroicons-outline:puzzle-piece",
    link: "events",
    isAdmin: false,
  },
  // {
  //   title: "Payouts",
  //   icon: "heroicons-outline:currency-dollar",
  //   isAdmin: false,
  //   link: "payouts",
  // },
  // {
  //   title: "Transactions",
  //   icon: "heroicons-outline:currency-dollar",
  //   isAdmin: false,
  //   link: "transactions",
  // },
  {
    title: "Forms",
    icon: "heroicons-outline:currency-dollar",
    isAdmin: false,
    link: "forms",
  },
  {
    title: "Tickets",
    icon: "heroicons-outline:home",
    isAdmin: false,
    link: "tickets",
  },
  {
    title: "Settings",
    icon: "heroicons-outline:currency-dollar",
    isAdmin: false,
    link: "profile",
  },
  {
    title: "Home",
    icon: "heroicons:shield-check",
    isAdmin: true,
    link: "/super-admin",
  },
  {
    title: "Events",
    icon: "heroicons:shield-check",
    isAdmin: true,
    link: "/super-admin/events",
  },
  {
    title: "Organisers",
    icon: "heroicons:shield-check",
    isAdmin: true,
    link: "/super-admin/organisers",
  },
  // {
  //   title: "Payouts",
  //   icon: "heroicons:shield-check",
  //   isAdmin: true,
  //   link: "/super-admin/payouts",
  // },
  {
    title: "Dashboard",
    icon: "heroicons:shield-check",
    isPartner: true,
    link: "/partners",
  },
  // {
  //   title: "Events",
  //   icon: "heroicons:shield-check",
  //   isPartner: true,
  //   link: "/partners/events",
  // },
  {
    title: "Organisers",
    icon: "heroicons:shield-check",
    isPartner: true,
    link: "/partners/organisers",
  },

  // {
  //   title: "Dashboard",
  //   icon: "heroicons-outline:home",
  //   isOpen: true,
  //   isHide: true,
  //   child: [
  //     {
  //       childtitle: "Analytics Dashboard",
  //       childlink: "dashboard",
  //     },
  //     {
  //       childtitle: "Ecommerce Dashboard",
  //       childlink: "ecommerce",
  //     },
  //     {
  //       childtitle: "Project  Dashbaord",
  //       childlink: "project",
  //     },
  //     {
  //       childtitle: " CRM Dashbaord",
  //       childlink: "crm",
  //     },
  //     {
  //       childtitle: "Banking Dashboard",
  //       childlink: "banking",
  //     },
  //   ],
  // },
  // {
  //   title: "changelog",
  //   icon: "heroicons:arrow-trending-up",
  //   link: "changelog",
  //   isHide: false,
  //   badge: "1.0.0",
  // },
  // {
  //   isHeadr: true,
  //   title: "Events",
  // },

  // {
  //   title: "All Events",
  //   isHide: true,
  //   icon: "heroicons-outline:puzzle-piece",
  //   link: "chat",
  // },

  // {
  //   title: "Create Event",
  //   isHide: true,
  //   icon: "heroicons-outline:plus-circle",
  //   link: "email",
  // },

  // {
  //   title: "Kanban",
  //   isHide: true,
  //   icon: "heroicons-outline:view-boards",
  //   link: "kanban",
  // },
  // {
  //   title: "Calender",
  //   isHide: true,
  //   icon: "heroicons-outline:calendar",
  //   link: "calender",
  // },

  // {
  //   title: "Todo",
  //   isHide: true,
  //   icon: "heroicons-outline:clipboard-check",
  //   link: "todo",
  // },

  // {
  //   title: "Projects",
  //   icon: "heroicons-outline:document",
  //   link: "#",
  //   isHide: true,
  //   child: [
  //     {
  //       childtitle: "Projects",
  //       childlink: "projects",
  //     },
  //     {
  //       childtitle: "Project Details",
  //       childlink: "project-details",
  //     },
  //   ],
  // },
  // {
  //   title: "Ecommerce",
  //   icon: "heroicons:shopping-bag",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "User App",

  //       multi_menu: [
  //         {
  //           multiTitle: "Products",
  //           multiLink: "products",
  //         },
  //         {
  //           multiTitle: "Products Details",
  //           multiLink: "products/1",
  //         },

  //         {
  //           multiTitle: "Cart",
  //           multiLink: "cart",
  //         },
  //         {
  //           multiTitle: "Wishlist",
  //           multiLink: "wishlist",
  //         },
  //       ],
  //     },
  //     {
  //       childtitle: "Admin App",

  //       multi_menu: [
  //         {
  //           multiTitle: "Orders",
  //           multiLink: "orders",
  //           badge: "soon",
  //         },

  //         {
  //           multiTitle: "Add Product",
  //           multiLink: "add-product",
  //           badge: "soon",
  //         },
  //         {
  //           multiTitle: "Edit Product",
  //           multiLink: "edit-product",
  //           badge: "soon",
  //         },
  //         {
  //           multiTitle: "Customers",
  //           multiLink: "customers",
  //           badge: "soon",
  //         },
  //         {
  //           multiTitle: "Sellers",
  //           multiLink: "sellers",
  //           badge: "soon",
  //         },
  //         {
  //           multiTitle: "Invoice",
  //           multiLink: "invoice-ecommerce",
  //           badge: "soon",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   isHeadr: true,
  //   title: "Pages",
  // },
  // {
  //   title: "Authentication",
  //   icon: "heroicons-outline:lock-closed",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Signin One",
  //       childlink: "/",
  //     },
  //     {
  //       childtitle: "Signin Two",
  //       childlink: "/login2",
  //     },
  //     {
  //       childtitle: "Signin Three",
  //       childlink: "/login3",
  //     },
  //     {
  //       childtitle: "Signup One",
  //       childlink: "/reg",
  //     },
  //     {
  //       childtitle: "Signup Two",
  //       childlink: "/reg2",
  //     },
  //     {
  //       childtitle: "Signup Three",
  //       childlink: "/reg3",
  //     },
  //     {
  //       childtitle: "Forget Password One",
  //       childlink: "/forgot-password",
  //     },
  //     {
  //       childtitle: "Forget Password Two",
  //       childlink: "/forgot-password2",
  //     },
  //     {
  //       childtitle: "Forget Password Three",
  //       childlink: "/forgot-password3",
  //     },
  //     {
  //       childtitle: "Lock Screen One",
  //       childlink: "/lock-screen",
  //     },
  //     {
  //       childtitle: "Lock Screen Two",
  //       childlink: "/lock-screen2",
  //     },
  //     {
  //       childtitle: "Lock Screen Three",
  //       childlink: "/lock-screen3",
  //     },
  //   ],
  // },
  // {
  //   title: "Utility",
  //   icon: "heroicons-outline:view-boards",
  //   link: "#",
  //   isHide: false,
  //   child: [
  //     {
  //       childtitle: "Invoice",
  //       childlink: "invoice",
  //     },
  //     {
  //       childtitle: "Pricing",
  //       childlink: "pricing",
  //     },
  //     // {
  //     //   childtitle: "Testimonial",
  //     //   childlink: "testimonial",
  //     // },
  //     {
  //       childtitle: "FAQ",
  //       childlink: "faq",
  //     },
  //     {
  //       childtitle: "Blog",
  //       childlink: "blog",
  //     },
  //     {
  //       childtitle: "Blank page",
  //       childlink: "blank-page",
  //     },
  //     {
  //       childtitle: "Prfoile",
  //       childlink: "profile",
  //     },
  //     {
  //       childtitle: "Settings",
  //       childlink: "settings",
  //     },
  //     {
  //       childtitle: "404 page",
  //       childlink: "/404",
  //     },

  //     {
  //       childtitle: "Coming Soon",
  //       childlink: "/coming-soon",
  //     },
  //     {
  //       childtitle: "Under Maintanance page",
  //       childlink: "/under-construction",
  //     },
  //   ],
  // },
  // {
  //   isHeadr: true,
  //   title: "Elements",
  // },
  // {
  //   title: "Widgets",
  //   icon: "heroicons-outline:view-grid-add",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Basic",
  //       childlink: "basic",
  //     },
  //     {
  //       childtitle: "Statistic",
  //       childlink: "statistic",
  //     },
  //   ],
  // },
  // {
  //   title: "Components",
  //   icon: "heroicons-outline:collection",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Typography",
  //       childlink: "typography",
  //     },
  //     {
  //       childtitle: "Colors",
  //       childlink: "colors",
  //     },
  //     {
  //       childtitle: "Alert",
  //       childlink: "alert",
  //     },
  //     {
  //       childtitle: "Button",
  //       childlink: "button",
  //     },
  //     {
  //       childtitle: "Card",
  //       childlink: "card",
  //     },
  //     {
  //       childtitle: "Carousel",
  //       childlink: "carousel",
  //     },
  //     {
  //       childtitle: "Dropdown",
  //       childlink: "dropdown",
  //     },
  //     {
  //       childtitle: "Image",
  //       childlink: "image",
  //     },
  //     {
  //       childtitle: "Modal",
  //       childlink: "modal",
  //     },
  //     {
  //       childtitle: "Progress bar",
  //       childlink: "progress-bar",
  //     },
  //     {
  //       childtitle: "Placeholder",
  //       childlink: "placeholder",
  //     },
  //     {
  //       childtitle: "Tab & Accordion",
  //       childlink: "tab-accordion",
  //     },
  //     {
  //       childtitle: "Badges",
  //       childlink: "badges",
  //     },
  //     {
  //       childtitle: "Paginatins",
  //       childlink: "paginations",
  //     },
  //     {
  //       childtitle: "Video",
  //       childlink: "video",
  //     },
  //     {
  //       childtitle: "Tooltip & Popover",
  //       childlink: "tooltip-popover",
  //     },
  //   ],
  // },
  // {
  //   title: "Forms",
  //   icon: "heroicons-outline:clipboard-list",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Input",
  //       childlink: "input",
  //     },
  //     {
  //       childtitle: "Input group",
  //       childlink: "input-group",
  //     },
  //     {
  //       childtitle: "Input layout",
  //       childlink: "input-layout",
  //     },
  //     {
  //       childtitle: "Form validation",
  //       childlink: "form-validation",
  //     },
  //     {
  //       childtitle: "Wizard",
  //       childlink: "form-wizard",
  //     },
  //     {
  //       childtitle: "Input mask",
  //       childlink: "input-mask",
  //     },
  //     {
  //       childtitle: "File input",
  //       childlink: "file-input",
  //     },
  //     {
  //       childtitle: "Form repeater",
  //       childlink: "form-repeater",
  //     },
  //     {
  //       childtitle: "Textarea",
  //       childlink: "textarea",
  //     },
  //     {
  //       childtitle: "Checkbox",
  //       childlink: "checkbox",
  //     },
  //     {
  //       childtitle: "Radio button",
  //       childlink: "radio-button",
  //     },
  //     {
  //       childtitle: "Switch",
  //       childlink: "switch",
  //     },
  //     {
  //       childtitle: "Select & Vue select",
  //       childlink: "select",
  //     },
  //     {
  //       childtitle: "Date time picker",
  //       childlink: "date-time-picker",
  //     },
  //   ],
  // },
  // {
  //   title: "Table",
  //   icon: "heroicons-outline:table",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Basic Table",
  //       childlink: "table-basic",
  //     },
  //     {
  //       childtitle: "React Table",
  //       childlink: "react-table",
  //     },
  //   ],
  // },
  // {
  //   title: "Chart",
  //   icon: "heroicons-outline:chart-bar",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Apex chart",
  //       childlink: "appex-chart",
  //     },
  //     {
  //       childtitle: "Chart js",
  //       childlink: "chartjs",
  //     },
  //     {
  //       childtitle: "Recharts",
  //       childlink: "recharts",
  //     },
  //   ],
  // },
  // {
  //   title: "Map",
  //   icon: "heroicons-outline:map",
  //   link: "map",
  // },
  // {
  //   title: "Icons",
  //   icon: "heroicons-outline:emoji-happy",
  //   link: "icons",
  // },
  // {
  //   title: "Multi Level",
  //   icon: "heroicons:share",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Level 1.1",
  //       childlink: "icons",
  //     },
  //     {
  //       childtitle: "Level 1.2",
  //       childlink: "Level-1",
  //       multi_menu: [
  //         {
  //           multiTitle: "Level 2.1",
  //           multiLink: "Level-2",
  //         },
  //         {
  //           multiTitle: "Level 2.2",
  //           multiLink: "Level-2.3",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const topMenu = [
  {
    title: "Dashboard",
    icon: "heroicons-outline:home",
    link: "/app/home",
    child: [
      {
        childtitle: "Analytics Dashboarddddd",
        childlink: "dashboard",
        childicon: "heroicons:presentation-chart-line",
      },
      {
        childtitle: "Ecommerce Dashboard",
        childlink: "ecommerce",
        childicon: "heroicons:shopping-cart",
      },
      {
        childtitle: "Project  Dashboard",
        childlink: "project",
        childicon: "heroicons:briefcase",
      },
      {
        childtitle: "CRM Dashboard",
        childlink: "crm",
        childicon: "ri:customer-service-2-fill",
      },
      {
        childtitle: "Banking Dashboard",
        childlink: "banking",
        childicon: "heroicons:wrench-screwdriver",
      },
    ],
  },
  {
    title: "App",
    icon: "heroicons-outline:chip",
    link: "/app/home",
    child: [
      {
        childtitle: "Calendar",
        childlink: "calender",
        childicon: "heroicons-outline:calendar",
      },
      {
        childtitle: "Kanban",
        childlink: "kanban",
        childicon: "heroicons-outline:view-boards",
      },
      {
        childtitle: "Todo",
        childlink: "todo",
        childicon: "heroicons-outline:clipboard-check",
      },
      {
        childtitle: "Projects",
        childlink: "projects",
        childicon: "heroicons-outline:document",
      },
    ],
  },
  {
    title: "Pages",
    icon: "heroicons-outline:view-boards",
    link: "/app/home",
    megamenu: [
      {
        megamenutitle: "Authentication",
        megamenuicon: "heroicons-outline:user",
        singleMegamenu: [
          {
            m_childtitle: "Signin One",
            m_childlink: "/",
          },
          {
            m_childtitle: "Signin Two",
            m_childlink: "/login2",
          },
          {
            m_childtitle: "Signin Three",
            m_childlink: "/login3",
          },
          {
            m_childtitle: "Signup One",
            m_childlink: "/register",
          },
          {
            m_childtitle: "Signup Two",
            m_childlink: "/register/register2",
          },
          {
            m_childtitle: "Signup Three",
            m_childlink: "/register/register3",
          },
          {
            m_childtitle: "Forget Password One",
            m_childlink: "/forgot-password",
          },
          {
            m_childtitle: "Forget Password Two",
            m_childlink: "/forgot-password2",
          },
          {
            m_childtitle: "Forget Password Three",
            m_childlink: "/forgot-password3",
          },
          {
            m_childtitle: "Lock Screen One",
            m_childlink: "/lock-screen",
          },
          {
            m_childtitle: "Lock Screen Two",
            m_childlink: "/lock-screen2",
          },
          {
            m_childtitle: "Lock Screen Three",
            m_childlink: "/lock-screen3",
          },
        ],
      },

      {
        megamenutitle: "Components",
        megamenuicon: "heroicons-outline:user",
        singleMegamenu: [
          {
            m_childtitle: "typography",
            m_childlink: "typography",
          },
          {
            m_childtitle: "colors",
            m_childlink: "colors",
          },
          {
            m_childtitle: "alert",
            m_childlink: "alert",
          },
          {
            m_childtitle: "button",
            m_childlink: "button",
          },
          {
            m_childtitle: "card",
            m_childlink: "card",
          },
          {
            m_childtitle: "carousel",
            m_childlink: "carousel",
          },
          {
            m_childtitle: "dropdown",
            m_childlink: "dropdown",
          },
          {
            m_childtitle: "image",
            m_childlink: "image",
          },
          {
            m_childtitle: "modal",
            m_childlink: "modal",
          },
          {
            m_childtitle: "Progress bar",
            m_childlink: "progress-bar",
          },
          {
            m_childtitle: "Placeholder",
            m_childlink: "placeholder",
          },

          {
            m_childtitle: "Tab & Accordion",
            m_childlink: "tab-accordion",
          },
        ],
      },
      {
        megamenutitle: "Forms",
        megamenuicon: "heroicons-outline:user",
        singleMegamenu: [
          {
            m_childtitle: "Input",
            m_childlink: "input",
          },
          {
            m_childtitle: "Input group",
            m_childlink: "input-group",
          },
          {
            m_childtitle: "Input layout",
            m_childlink: "input-layout",
          },
          {
            m_childtitle: "Form validation",
            m_childlink: "form-validation",
          },
          {
            m_childtitle: "Wizard",
            m_childlink: "form-wizard",
          },
          {
            m_childtitle: "Input mask",
            m_childlink: "input-mask",
          },
          {
            m_childtitle: "File input",
            m_childlink: "file-input",
          },
          {
            m_childtitle: "Form repeater",
            m_childlink: "form-repeater",
          },
          {
            m_childtitle: "Textarea",
            m_childlink: "textarea",
          },
          {
            m_childtitle: "Checkbox",
            m_childlink: "checkbox",
          },
          {
            m_childtitle: "Radio button",
            m_childlink: "radio-button",
          },
          {
            m_childtitle: "Switch",
            m_childlink: "switch",
          },
        ],
      },
      {
        megamenutitle: "Utility",
        megamenuicon: "heroicons-outline:user",
        singleMegamenu: [
          {
            m_childtitle: "Invoice",
            m_childlink: "invoice",
          },
          {
            m_childtitle: "Pricing",
            m_childlink: "pricing",
          },

          // {
          //   m_childtitle: "Testimonial",
          //   m_childlink: "testimonial",
          // },
          {
            m_childtitle: "FAQ",
            m_childlink: "faq",
          },
          {
            m_childtitle: "Blank page",
            m_childlink: "blank-page",
          },
          {
            m_childtitle: "Blog",
            m_childlink: "blog",
          },
          {
            m_childtitle: "404 page",
            m_childlink: "/404",
          },
          {
            m_childtitle: "Coming Soon",
            m_childlink: "/coming-soon",
          },
          {
            m_childtitle: "Under Maintanance page",
            m_childlink: "/under-construction",
          },
        ],
      },
    ],
  },

  {
    title: "Widgets",
    icon: "heroicons-outline:view-grid-add",
    link: "form-elements",
    child: [
      {
        childtitle: "Basic",
        childlink: "basic",
        childicon: "heroicons-outline:document-text",
      },
      {
        childtitle: "Statistic",
        childlink: "statistic",
        childicon: "heroicons-outline:document-text",
      },
    ],
  },

  {
    title: "Extra",
    icon: "heroicons-outline:template",

    child: [
      {
        childtitle: "Basic Table",
        childlink: "table-basic",
        childicon: "heroicons-outline:table",
      },
      {
        childtitle: "Advanced table",
        childlink: "table-advanced",
        childicon: "heroicons-outline:table",
      },
      {
        childtitle: "Apex chart",
        childlink: "appex-chart",
        childicon: "heroicons-outline:chart-bar",
      },
      {
        childtitle: "Chart js",
        childlink: "chartjs",
        childicon: "heroicons-outline:chart-bar",
      },
      {
        childtitle: "Map",
        childlink: "map",
        childicon: "heroicons-outline:map",
      },
    ],
  },
];

import User1 from "@/assets/images/all-img/user.png";
import User2 from "@/assets/images/all-img/user2.png";
import User3 from "@/assets/images/all-img/user3.png";
import User4 from "@/assets/images/all-img/user4.png";
export const notifications = [
  {
    title: "Your order is placed",
    desc: "Amet minim mollit non deser unt ullamco est sit aliqua.",

    image: User1,
    link: "#",
  },
  {
    title: "Congratulations Darlene  🎉",
    desc: "Won the monthly best seller badge",
    unread: true,
    image: User2,
    link: "#",
  },
  {
    title: "Revised Order 👋",
    desc: "Won the monthly best seller badge",

    image: User3,
    link: "#",
  },
  {
    title: "Brooklyn Simmons",
    desc: "Added you to Top Secret Project group...",

    image: User4,
    link: "#",
  },
];

export const message = [
  {
    title: "Wade Warren",
    desc: "Hi! How are you doing?.....",
    active: true,
    hasnotifaction: true,
    notification_count: 1,
    image: User1,
    link: "#",
  },
  {
    title: "Savannah Nguyen",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: false,
    image: User2,
    link: "#",
  },
  {
    title: "Ralph Edwards",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: true,
    notification_count: 8,
    image: User3,
    link: "#",
  },
  {
    title: "Cody Fisher",
    desc: "Hi! How are you doing?.....",
    active: true,
    hasnotifaction: false,
    image: User4,
    link: "#",
  },
  {
    title: "Savannah Nguyen",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: false,
    image: User2,
    link: "#",
  },
  {
    title: "Ralph Edwards",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: true,
    notification_count: 8,
    image: User3,
    link: "#",
  },
  {
    title: "Cody Fisher",
    desc: "Hi! How are you doing?.....",
    active: true,
    hasnotifaction: false,
    image: User4,
    link: "#",
  },
];

export const colors = {
  primary: "#4669FA",
  secondary: "#A0AEC0",
  danger: "#F1595C",
  black: "#111112",
  warning: "#FA916B",
  info: "#0CE7FA",
  light: "#425466",
  success: "#50C793",
  "gray-f7": "#F7F8FC",
  dark: "#1E293B",
  "dark-gray": "#0F172A",
  gray: "#68768A",
  gray2: "#EEF1F9",
  "dark-light": "#CBD5E1",
};

export const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

export const topFilterLists = [
  {
    name: "Inbox",
    value: "all",
    icon: "uil:image-v",
  },
  {
    name: "Starred",
    value: "fav",
    icon: "heroicons:star",
  },
  {
    name: "Sent",
    value: "sent",
    icon: "heroicons-outline:paper-airplane",
  },

  {
    name: "Drafts",
    value: "drafts",
    icon: "heroicons-outline:pencil-alt",
  },
  {
    name: "Spam",
    value: "spam",
    icon: "heroicons:information-circle",
  },
  {
    name: "Trash",
    value: "trash",
    icon: "heroicons:trash",
  },
];

export const bottomFilterLists = [
  {
    name: "personal",
    value: "personal",
    icon: "heroicons:chevron-double-right",
  },
  {
    name: "Social",
    value: "social",
    icon: "heroicons:chevron-double-right",
  },
  {
    name: "Promotions",
    value: "promotions",
    icon: "heroicons:chevron-double-right",
  },
  {
    name: "Business",
    value: "business",
    icon: "heroicons:chevron-double-right",
  },
];

import meetsImage1 from "@/assets/images/svg/sk.svg";
import meetsImage2 from "@/assets/images/svg/path.svg";
import meetsImage3 from "@/assets/images/svg/dc.svg";
import meetsImage4 from "@/assets/images/svg/sk.svg";

export const meets = [
  {
    img: meetsImage1,
    title: "Meeting with client",
    date: "01 Nov 2021",
    meet: "Zoom meeting",
  },
  {
    img: meetsImage2,
    title: "Design meeting (team)",
    date: "01 Nov 2021",
    meet: "Skyp meeting",
  },
  {
    img: meetsImage3,
    title: "Background research",
    date: "01 Nov 2021",
    meet: "Google meeting",
  },
  {
    img: meetsImage4,
    title: "Meeting with client",
    date: "01 Nov 2021",
    meet: "Zoom meeting",
  },
];
import file1Img from "@/assets/images/icon/file-1.svg";
import file2Img from "@/assets/images/icon/pdf-1.svg";
import file3Img from "@/assets/images/icon/zip-1.svg";
import file4Img from "@/assets/images/icon/pdf-2.svg";
import file5Img from "@/assets/images/icon/scr-1.svg";

export const files = [
  {
    img: file1Img,
    title: "Dashboard.fig",
    date: "06 June 2021 / 155MB",
  },
  {
    img: file2Img,
    title: "Ecommerce.pdf",
    date: "06 June 2021 / 155MB",
  },
  {
    img: file3Img,
    title: "Job portal_app.zip",
    date: "06 June 2021 / 155MB",
  },
  {
    img: file4Img,
    title: "Ecommerce.pdf",
    date: "06 June 2021 / 155MB",
  },
  {
    img: file5Img,
    title: "Screenshot.jpg",
    date: "06 June 2021 / 155MB",
  },
];

export const categories = [
  { label: "All", value: "all", count: "9724" },
  { label: "Men", value: "men", count: "1312" },
  { label: "Women", value: "women", count: "3752" },
  { label: "Child", value: "child", count: "985" },
  { label: "Baby", value: "baby", count: "745" },
  { label: "Footwear", value: "footwear", count: "1280" },
  { label: "Furniture", value: "furniture", count: "820" },
  { label: "Mobile", value: "mobile", count: "2460" },
];

export const brands = [
  { label: "Apple", value: "apple", count: "9724" },
  { label: "Apex", value: "apex", count: "1312" },
  { label: "Easy", value: "easy", count: "3752" },
  { label: "Pixel", value: "pixel", count: "985" },
  { label: "Samsung", value: "samsung", count: "2460" },
];

export const price = [
  {
    label: "$0 - $199",
    value: {
      min: 0,
      max: 199,
    },
    count: "9724",
  },
  {
    label: "$200 - $449",
    value: {
      min: 200,
      max: 499,
    },
    count: "1312",
  },
  {
    label: "$450 - $599",
    value: {
      min: 450,
      max: 599,
    },
    count: "3752",
  },
  {
    label: "$600 - $799",
    value: {
      min: 600,
      max: 799,
    },
    count: "985",
  },
  {
    label: "$800 & Above",
    value: {
      min: 800,
      max: 1000,
    },
    count: "745",
  },
];

export const ratings = [
  { name: 5, value: 5, count: "9724" },
  { name: 4, value: 4, count: "1312" },
  { name: 3, value: 3, count: "3752" },
  { name: 2, value: 2, count: "985" },
  { name: 1, value: 1, count: "2460" },
];

export const selectOptions = [
  {
    value: "option1",
    label: "Option 1",
  },
  {
    value: "option2",
    label: "Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
  },
];
export const selectCategory = [
  {
    value: "option1",
    label: "Top Rated",
  },
  {
    value: "option2",
    label: "Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
  },
];
