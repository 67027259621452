import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import AuthLayout from "./layout/AuthLayout";
import Layout from "./layout/Layout";
import EventPostPage from "./pages/app/events";
import NomineeFormsListPage from "./pages/dashboard/NominationFormsList";
import SuperAdminLayout from "./layout/SuperAdminLayout";
import CategoryForm from "./pages/app/events/CategoryWithNomineesForm";
import SuperAdmin from "./pages/app/superadmin/SuperAdmin";
import SuperAdminEvents from "./pages/app/superadmin/SuperAdminEvents";
import AdminOrganisersList from "./pages/app/superadmin/SuperAdminOrganisers copy";
import AdminPayoutsList from "./pages/app/superadmin/SuperAdminPayouts";
import EventTicketForm from "./pages/dashboard/EventTicketForm";
import EventTicketsList from "./pages/dashboard/EventTicketsList";
import PartnerLayout from "./layout/PartnerLayout";
import PartnerDashboard from "./pages/partner/PartnerDashboard";
import PartnerEventsList from "./pages/partner/PartnerEventsList";
import PartnerOrganisersList from "./pages/partner/PartnerOrganisersList";
import PartnerOrganiserDetails from "./pages/partner/PartnerOrganiserDetails";

const NominationSubmissions = lazy(() =>
  import("./pages/dashboard/nominationSubmissions")
);
const Transactions = lazy(() => import("./pages/transactions"));
const Settings = lazy(() => import("./pages/utility/settings"));
const Profile = lazy(() => import("./pages/utility/profile"));

const BankingPage = lazy(() => import("./pages/dashboard/banking"));
const PayoutList = lazy(() => import("./pages/dashboard/PayoutList"));

const Login = lazy(() => import("./pages/auth/login"));
const Register = lazy(() => import("./pages/auth/register"));
const ForgotPass = lazy(() => import("./pages/auth/forgot-password"));

const EventDetailsPage = lazy(() => import("./pages/app/events/event-details"));
const EventNomineePage = lazy(() =>
  import("./pages/app/events/EventNomineePage")
);
const Votes = lazy(() => import("./pages/dashboard/Votes"));
const EventVoteTransactions = lazy(() =>
  import("./pages/dashboard/event-vote-transactions")
);

const CategoryPaymentTransactions = lazy(() =>
  import("./pages/dashboard/category-payment-transactions")
);

const DelegateForm = lazy(() => import("./pages/dashboard/DelegateForm"));
const CreateNomineeForm = lazy(() =>
  import("./pages/dashboard/CreateNominationsForm")
);

const router = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPass />,
      },
    ],
  },
  {
    path: "/nominee-filing",
    children: [
      {
        path: "",
        element: <DelegateForm />,
      },
    ],
  },
  {
    element: (
      <AuthProvider>
        <Layout />
      </AuthProvider>
    ),
    path: "",
    children: [
      {
        path: "",
        element: <BankingPage />,
      },
      {
        path: "events/:id",
        element: <EventDetailsPage />,
      },
      {
        path: "categories/:id",
        element: <CategoryForm />,
      },
      {
        path: "categories/:eventId/new",
        element: <CategoryForm />,
      },
      {
        path: "events",
        element: <EventPostPage />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "payouts",
        element: <PayoutList />,
      },
      {
        path: "event-nominee/:id",
        element: <EventNomineePage />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "votes/:categoryId",
        element: <Votes />,
      },
      {
        path: "payment-transactions/:categoryId",
        element: <CategoryPaymentTransactions />,
      },
      {
        path: "events/:eventId/vote-transactions",
        element: <EventVoteTransactions />,
      },
      {
        path: "forms",
        children: [
          {
            path: "",
            element: <NomineeFormsListPage />,
          },
        ],
      },
      {
        path: "nomination-submissions/:eventId",
        children: [
          {
            path: "",
            element: <NominationSubmissions />,
          },
        ],
      },
      {
        path: "forms/new",
        children: [
          {
            path: "",
            element: <CreateNomineeForm />,
          },
        ],
      },
      {
        path: "forms/:eventId",
        children: [
          {
            path: "",
            element: <CreateNomineeForm />,
          },
        ],
      },
      {
        path: "tickets",
        element: <EventTicketsList />,
      },
      {
        path: "tickets/:id",
        children: [
          {
            path: "",
            element: <EventTicketForm />,
          },
        ],
      },
      {
        path: "tickets/new",
        children: [
          {
            path: "",
            element: <EventTicketForm />,
          },
        ],
      },
    ],
  },
  {
    element: (
      <AuthProvider>
        <SuperAdminLayout />
      </AuthProvider>
    ),
    path: "super-admin",
    children: [
      {
        path: "",
        element: <SuperAdmin />,
      },
      {
        path: "events",
        element: <SuperAdminEvents />,
      },
      {
        path: "organisers",
        element: <AdminOrganisersList />,
      },
      {
        path: "payouts",
        element: <AdminPayoutsList />,
      },
    ],
  },
  {
    element: (
      <AuthProvider>
        <PartnerLayout />
      </AuthProvider>
    ),
    path: "partners",
    children: [
      {
        path: "",
        element: <PartnerDashboard />,
      },
      {
        path: "events",
        element: <PartnerEventsList />,
      },
      {
        path: "organisers",
        element: <PartnerOrganisersList />,
      },
      {
        path: "organisers/:id",
        element: <PartnerOrganiserDetails />,
      },
    ],
  },
]);

export default router;
