import Card from "@/components/ui/Card";
import useWidth from "@/hooks/useWidth";
import { protectedApi } from "@/store/api/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EventsDataTable from "./EventsDataTable";

const SuperAdminEvents = () => {
  const [events, setEvents] = useState([]);
  const { width, breakpoints } = useWidth();
  const navigate = useNavigate();

  const getEvents = () => {
    protectedApi
      .get("/api/events/all")
      .then((res) => setEvents(res.data.data))
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="space-y-5">
      <div className="flex flex-wrap justify-between items-center mb-4">
        <h4 className="font-medium lg:text-2xl text-xl capitalize text-slate-900 inline-block ltr:pr-4 rtl:pl-4">
          All Events List
        </h4>
        <div
          className={`${
            width < breakpoints.md ? "space-x-rb" : ""
          } md:flex md:space-x-4 md:justify-end items-center rtl:space-x-reverse mt-3`}
        ></div>
      </div>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <div className="space-y-5 bank-table">
            {events.length > 0 && (
              <Card>
                <EventsDataTable data={events} tableActions={tableActions} />
              </Card>
            )}

            {events.length === 0 && (
              <div className="text-center mt-16">
                <div className="mb-4">
                  <svg
                    className="w-20 h-20 text-gray-400 mx-auto"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* Folder Icon */}
                    <path d="M3 5H1v16c0 1.1.9 2 2 2h18v-2H3V5z"></path>
                    <path d="M21 3H11l-2-2H3c-1.1 0-2 .9-2 2v4h20V5c0-1.1-.9-2-2-2z"></path>
                    {/* Lightbulb Icon */}
                    <path d="M13 10.5v3h-2v-3c0-.8.7-1.5 1.5-1.5S13 9.7 13 10.5zM12 18c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2z"></path>
                    <path d="M8.56 14.23l1.43 1.43c-.67.18-1.3.48-1.81.9-.5.41-.9.93-1.19 1.5l-1.43-1.43c.51-.98 1.21-1.8 2.11-2.4.43-.29.91-.53 1.4-.73l-.6-.17zm6.88 0c.49.2.97.44 1.4.73.9.6 1.6 1.42 2.11 2.4l-1.43 1.43c-.29-.57-.69-1.09-1.19-1.5-.51-.42-1.14-.72-1.81-.9l1.43-1.43-.51-.23z"></path>
                  </svg>
                </div>
                <h1 className="text-xl mb-4">No events available</h1>
                <p className="mb-4">
                  Events created by organisers will show up here
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const tableActions = [
  {
    name: "view",
    icon: "heroicons-outline:eye",
  },
  {
    name: "edit",
    icon: "heroicons:pencil-square",
  },
  {
    name: "delete",
    icon: "heroicons-outline:trash",
  },
];

export default SuperAdminEvents;
