import { createSlice } from "@reduxjs/toolkit";
import {
  fetchEvents,
  fetchEventCategory,
  fetchEventCategoryNominees,
  createNewEvent,
  createNominee,
  createCategory,
  deleteNominee,
  evictNominee,
  restoreNominee,
  editNominee,
  editEvent,
  editCategory,
} from "../../../utils/eventService";

const initialState = {
  message: "",
  openEventModal: false,
  isLoading: null,
  editItem: {},
  editModal: false,
  events: [],
  eventCategory: [],
  eventCategoryNominees: [],
};

export const appProjectSlice = createSlice({
  name: "approject",
  initialState,
  reducers: {
    toggleAddModal: (state, action) => {
      state.openEventModal = action.payload;
    },
    toggleEditModal: (state, action) => {
      state.editModal = action.payload;
    },
    updateEvent: (state, action) => {
      state.editItem = action.payload;
      // toggle edit modal
      state.editModal = !state.editModal;
      // find index
      let index = state.events.findIndex(
        (item) => item.id === action.payload.id
      );
    },
    resetMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchEvents.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        events: action.payload,
      }))
      .addCase(fetchEvents.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchEventCategory.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchEventCategory.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        eventCategory: action.payload,
      }))
      .addCase(fetchEventCategory.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchEventCategoryNominees.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchEventCategoryNominees.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        eventCategoryNominees: action.payload,
      }))
      .addCase(fetchEventCategoryNominees.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(createNewEvent.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(createNewEvent.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(createNewEvent.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(createNominee.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(createNominee.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(createNominee.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(createCategory.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(createCategory.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(createCategory.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(deleteNominee.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(deleteNominee.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload,
      }))
      .addCase(deleteNominee.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload,
      }))
      .addCase(evictNominee.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(evictNominee.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(evictNominee.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(editNominee.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(editNominee.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(editNominee.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload,
      }))
      .addCase(editEvent.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(editEvent.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(editEvent.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload,
      }))
      .addCase(editCategory.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(editCategory.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(editCategory.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(restoreNominee.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(restoreNominee.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }))
      .addCase(restoreNominee.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        message: action.payload.message,
      }));
  },
});

export const {
  openModal,
  toggleAddModal,
  removeEvent,
  toggleEditModal,
  updateEvent,
  resetMessage,
} = appProjectSlice.actions;
export default appProjectSlice.reducer;
