import { LoadingIcon } from "@/components/Loading";
import Card from "@/components/ui/Card";
import { protectedApi } from "@/store/api/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventGrid from "../app/events/EventGrid";
import NotFound from "../components/not-found";
import HomeBredCurbs from "../dashboard/HomeBredCurbs";

const PartnerOrganiserDetails = () => {
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [expiredEvents, setExpiredEvents] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      protectedApi
        .get(`/api/events/all/${id}`)
        .then((res) => {
          const events = res.data.data;
          setActiveEvents(events.activeEvents);
          setPendingEvents(events.pendingEvents);
          setExpiredEvents(events.expiredEvents);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  if (isLoading)
    return (
      <Card className="flex justify-center items-center h-screen">
        <LoadingIcon />
      </Card>
    );

  return (
    <div className="space-y-5">
      <HomeBredCurbs title="Organiser Details" showDatepicker={false} />
      <div className="mt-10">
        <div className="my-8">
          <h6 className="mb-7">Active Events</h6>
          {activeEvents.length > 0 ? (
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
              {activeEvents.map((event, eventIndex) => (
                <EventGrid
                  event={event}
                  key={eventIndex}
                  showLaunchBtn={false}
                  showOnlyCategoriesAction={true}
                />
              ))}
            </div>
          ) : (
            <NotFound model={"active events"} />
          )}
        </div>
        <div className="my-8">
          <h6 className="mb-7">Pending Events</h6>
          {pendingEvents.length > 0 ? (
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
              {pendingEvents.map((event, eventIndex) => (
                <EventGrid
                  event={event}
                  key={eventIndex}
                  showLaunchBtn={false}
                />
              ))}
            </div>
          ) : (
            <NotFound model={"pending events"} />
          )}
        </div>
        <div className="my-8">
          <h6 className="mb-7">Expired Events</h6>
          {expiredEvents.length > 0 ? (
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
              {expiredEvents.map((event, eventIndex) => (
                <EventGrid
                  event={event}
                  key={eventIndex}
                  showLaunchBtn={false}
                />
              ))}
            </div>
          ) : (
            <NotFound model={"expired events"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerOrganiserDetails;
