import Button from "@/components/ui/Button";
import Card from "@/components/ui/Card";
import { useAuth } from "@/contexts/AuthContext";
import { firebaseDb } from "@/pages/auth/common/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const EventTicketsList = () => {
  const [ticketEvents, setTicketEvents] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();

  const launchTicketPurchase = async (eventId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to open ticket purchase for this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, open it!",
    });

    if (result.isConfirmed) {
      try {
        const eventDocRef = doc(firebaseDb, "ticket-events", eventId);
        await updateDoc(eventDocRef, { status: "active" });
        toast.success("Ticket purchase has been opened for this event");

        setTicketEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === eventId ? { ...event, status: "active" } : event
          )
        );
      } catch (error) {
        console.error("Error updating event status: ", error);
        toast.error("Error updating event status");
      }
    }
  };

  const endTicketPurchase = async (eventId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to end ticket purchase for this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, end it!",
    });

    if (result.isConfirmed) {
      try {
        const eventDocRef = doc(firebaseDb, "ticket-events", eventId);
        await updateDoc(eventDocRef, { status: "ENDED" });
        toast.success("Ticket purchase has been ended for this event");

        setTicketEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === eventId ? { ...event, status: "ENDED" } : event
          )
        );
      } catch (error) {
        console.error("Error updating event status: ", error);
        toast.error("Error ending ticket purchase");
      }
    }
  };

  useEffect(() => {
    const fetchTicketEvents = async (userId) => {
      try {
        const eventsQuery = query(
          collection(firebaseDb, "ticket-events"),
          where("organiserId", "==", userId)
        );
        const querySnapshot = await getDocs(eventsQuery);
        const events = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTicketEvents(events);
      } catch (error) {
        console.error("Error fetching ticket events: ", error);
        toast.error("Error fetching ticket events");
      }
    };

    if (user && user.uid) {
      fetchTicketEvents(user.uid);
    }
  }, [user]);

  return (
    <div className="space-y-5">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Ticket Events List</h1>
        <Button
          icon="heroicons-outline:plus"
          text="Create New Event Ticket"
          className="btn-dark dark:bg-slate-800 h-min text-sm font-normal ml-2 bg-green-500 text-white hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out"
          iconClass="text-lg"
          onClick={() => navigate("/tickets/new")}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {ticketEvents.map((event) => (
          <Card key={event.id}>
            <img
              className="w-full mb-5 h-96 object-cover"
              src={event.eventImage}
            />
            <h6 className="mb-4">{event.eventName}</h6>
            <p>
              <strong>Venue:</strong> {event.venueName}
            </p>
            <p>
              <strong>City:</strong> {event.venueCity}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {moment(event.eventDate).format("DD-MM-YYYY, hh:mm:ss a")}
            </p>
            <p>
              <strong>Start Time:</strong>{" "}
              {new Date(event.eventStartTime).toLocaleTimeString()}
            </p>
            <p>
              <strong>End Time:</strong>{" "}
              {new Date(event.eventEndTime).toLocaleTimeString()}
            </p>

            <p className="mt-12">{event.purchaseCount || 0} tickets bought</p>
            <div className="flex justify-between space-x-6 mt-4">
              <Button
                text="Ticket Purchases"
                className="btn-dark block"
                onClick={() => {
                }}
              />
              <Button
                text="Edit Ticket Event"
                className="btn-light block"
                onClick={() => {
                  navigate(`/tickets/${event.id}`);
                }}
              />
            </div>
            <div className="mt-8">
              {event.status === "CREATED" ||
              event.status === "ENDED" ||
              !event.status ? (
                <Button
                  text="Open Ticket Purchase"
                  className="btn-light bg-blue-500 w-full block"
                  onClick={() => launchTicketPurchase(event.id)}
                />
              ) : (
                <Button
                  icon="heroicons-outline:trash"
                  text="End Tickets Purchase"
                  className="btn-light bg-red-600 text-white w-full block"
                  onClick={() => endTicketPurchase(event.id)}
                />
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default EventTicketsList;
