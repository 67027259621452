import React, { useEffect, useState } from "react";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { storage } from "../../../pages/auth/common/firebaseConfig";

const ImageUploadInput = ({
  label,
  onImageUrlChange,
  defaultValue,
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(defaultValue || "");

  useEffect(() => {
    setImageUrl(defaultValue);
  }, [defaultValue]);

  const handleChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        if (file.size > 2 * 1024 * 1024) {
          throw new Error("File size exceeds 2MB limit");
        }

        const allowedFormats = ["jpg", "jpeg", "png"];
        const fileFormat = file.name.split(".").pop().toLowerCase();
        if (!allowedFormats.includes(fileFormat)) {
          throw new Error(
            "Invalid file format. Allowed formats: jpg, jpeg, png"
          );
        }

        const storageRef = ref(storage, `images/${file.name}`);

        await uploadBytes(storageRef, file);

        const url = await getDownloadURL(storageRef);
        setImageUrl(url);
        if (onImageUrlChange) {
          onImageUrlChange(url);
        }
      } catch (error) {
        console.error(error);
        setImageUrl("");
        if (onImageUrlChange) {
          onImageUrlChange("");
        }
      }
    } else {
      setImageUrl("");
      if (onImageUrlChange) {
        onImageUrlChange("");
      }
    }
  };

  return (
    <div>
      <label>{label}</label>
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={handleChange}
        {...props}
        className="mb-4"
      />
      {imageUrl && <img src={imageUrl} alt="Uploaded Preview" />}
    </div>
  );
};

export { ImageUploadInput };
