import { LoadingIcon } from "@/components/Loading";
import { protectedApi } from "@/store/api/api";
import React, { useState, useEffect } from "react";
import HomeBredCurbs from "../dashboard/HomeBredCurbs";
import Card from "@/components/ui/Card";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";

const PartnerOrganisersList = () => {
  const [organisers, setOrganisers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastVisibleDocId, setLastVisibleDocId] = useState(null);
  const pageSize = 10; // Define how many items you want per page
  const navigate = useNavigate();

  const getOrganisers = () => {
    setIsLoading(true);

    const endpoint = lastVisibleDocId
      ? `/api/organisers/all?pageSize=${pageSize}&lastVisibleDocId=${lastVisibleDocId}`
      : `/api/organisers/all?pageSize=${pageSize}`;

    protectedApi
      .get(endpoint)
      .then((res) => {
        const data = res.data.data;
        setOrganisers((prev) => [...prev, ...data]);
        if (data.length > 1) setLastVisibleDocId(data[data.length - 1].id);
      })
      .catch((err) => console.error("Error fetching organisers:", err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getOrganisers(pageNumber);
  }, []);

  if (isLoading)
    return (
      <Card className="flex items-center h-screen justify-center">
        <LoadingIcon />
      </Card>
    );

  return (
    <div>
      <div className="space-y-5">
        <HomeBredCurbs title="Organisers List" showDatepicker={false} />
        <Card>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-slate-100 table-fixed dark:divide-slate-700">
              <thead className="border-t border-slate-100 dark:border-slate-800">
                <tr className="w-full">
                  <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                    #
                  </th>
                  <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                    First Name
                  </th>
                  <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                    Last Name
                  </th>
                  <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                    Phone
                  </th>
                  <th className="table-th px-6 py-3 font-medium text-left text-gray-900 whitespace-nowrap">
                    Email
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {organisers.map((item, index) => (
                  <tr
                    key={item.id}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.firstName || item.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.lastName || ""}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.phone || ""}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.email}
                    </td>
                    <td>
                      <button onClick={() => {
                        navigate(`/partners/organisers/${item.id}`)
                      }}>
                        <Icon
                          icon="heroicons-outline:information-circle"
                          className="text-2xl text-green-600 hover:text-pink-700"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PartnerOrganisersList;
