import layout from "./layout";
import todo from "../pages/app/todo/store";
import email from "../pages/app/email/store";
import chat from "../pages/app/chat/store";
import event from "../pages/app/events/store";
import auth from "./api/auth/authSlice";

const rootReducer = {
  layout,
  todo,
  email,
  chat,
  event,
  auth,
};
export default rootReducer;
