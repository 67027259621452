import Card from "@/components/ui/Card";
import HomeBredCurbs from "./HomeBredCurbs";
import { protectedApi } from "@/store/api/api";
import { useEffect, useState } from "react";
import Button from "@/components/ui/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";

const NomineeFormsListPage = () => {
  const [nominationForms, setNominationForms] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    protectedApi
      .get("/api/nomination-forms")
      .then((res) => setNominationForms(res.data.data))
      .catch((err) => console.log(err));
  }, [formSubmitted]);

  const handleLaunch = (formId) => {
    protectedApi.put(`/api/nomination-forms/${formId}/launch`).then((res) => {
      toast.success(res.data.message);
      reloadPage();
    });
  };

  const handleEndLaunch = (formId) => {
    protectedApi
      .delete(`/api/nomination-forms/${formId}/endLaunch`)
      .then((res) => {
        toast.success(res.data.message);
        reloadPage();
      });
  };

  const launchForm = (formId) => {
    Swal.fire({
      title: `Are you sure you want to launch this nomination form?`,
      icon: "success",
      showCancelButton: true,
      cancelButtonColor: "#ef4444",
      confirmButtonColor: "#22c55e",
      confirmButtonText: "Yes, launch it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) handleLaunch(formId);
      })
      .catch((e) =>
        toast.error(e.data?.message || e.message || "something went wrong")
      );
  };

  const endFormLaunch = (formId) => {
    Swal.fire({
      title: `Are you sure you want to end this nomination form?`,
      icon: "success",
      showCancelButton: true,
      cancelButtonColor: "#ef4444",
      confirmButtonColor: "#22c55e",
      confirmButtonText: "Yes, end it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) handleEndLaunch(formId);
      })
      .catch((e) =>
        toast.error(e.data?.message || e.message || "something went wrong")
      );
  };

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const deleteForm = async (formId) => {
    Swal.fire({
      title: `Are you sure you want to delete this nomination form?`,
      icon: "success",
      showCancelButton: true,
      cancelButtonColor: "#ef4444",
      confirmButtonColor: "#22c55e",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          protectedApi.delete(`/api/nomination-forms/${formId}`).then((res) => {
            toast.success(res.data.message);
            reloadPage();
          });
        }
      })
      .catch((e) =>
        toast.error(e.data?.message || e.message || "something went wrong")
      );
  };

  return (
    <div className="space-y-5">
      <div className="flex justify-between">
        <HomeBredCurbs showDatepicker={false} title="Nomination Forms List" />
        <Button
          icon="heroicons-outline:plus"
          text="Create New Form"
          className="btn-dark dark:bg-slate-800 h-min text-sm font-normal ml-2 bg-green-500 text-white hover:bg-green-600 hover:text-white transition-colors duration-300 ease-in-out"
          iconClass=" text-lg"
          onClick={() => {
            navigate("/forms/new");
          }}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {nominationForms.map((item, index) => (
          <Card key={index}>
            <img
              className="w-full mb-5 h-96 object-cover"
              src={item.imageUrl}
            />
            <div className="flex justify-between">
              <h6>{item.eventTitle}</h6>
              {!item.submissionsCount && (
                <div className="flex items-center">
                  <button onClick={async () => await deleteForm(item.id)}>
                    <Icon
                      icon="heroicons-outline:trash"
                      className="text-red-500 text-xl"
                    />
                  </button>
                </div>
              )}
            </div>
            <p className="mt-4">{item.submissionsCount || 0} Submissions</p>
            <div className="flex justify-between space-x-6 mt-8">
              <Button
                text="Submissions"
                className="btn-dark block"
                onClick={() => {
                  navigate(`/nomination-submissions/${item.id}`);
                }}
              />
              <Button
                text="Edit Form"
                className="btn-light block"
                onClick={() => {
                  navigate(`/forms/${item.id}`);
                }}
              />
            </div>
            <div className="mt-8">
              {item.status === "CREATED" || item.status === "ENDED" ? (
                <Button
                  text="Open Nominations"
                  className="btn-light bg-blue-500 w-full block"
                  onClick={() => launchForm(item.id)}
                />
              ) : (
                <Button
                  icon="heroicons-outline:trash"
                  text="End Nominations"
                  className="btn-light bg-red-600 text-white w-full block"
                  onClick={() => endFormLaunch(item.id)}
                />
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default NomineeFormsListPage;
